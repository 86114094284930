<template>
  <CoreModal
    modal="rareAnimals"
    image-src="/images/modals/rareAnimals.jpg"
  >
    <template v-slot:title>
      Знай наших!
    </template>
    <template v-slot:text>
      <div>
        На территории наших лесных аренд можно увидеть
        таких редких птиц, как дупель
        <span class="stop-line-br">или серый</span> журавль,
        встретить
        <span class="stop-line-br">не менее редкие</span> растения — «‎настоящий
        башмачок Венеры»‎
        <span class="stop-line-br">или «‎угловатый лук»‎.</span>
        <br><br>
        <span class="stop-line-br">Мы бережно</span> сохраняем места обитания целого десятка
        популяций редких животных
        <span class="stop-line-br">и свыше</span> шестидесяти
        уникальных видов растений. Проявляя заботу
        <span class="stop-line-br">о пернатых,</span> ежегодно устанавливаем кормушки,
        скворечники
        <span class="stop-line-br">и гнездовья</span> для птиц.
      </div>
    </template>
  </CoreModal>
</template>

<script>
import CoreModal from '@components/CoreModal';

export default {
  name: 'RareAnimalsModal',
  components: { CoreModal },
};
</script>

<style scoped>

</style>
