<template>
  <div class="test-question">
    <CoreContainer class="test-question__container" :class="{'test-question__container-gutter': !isQuestionAnswered}">
      <img class="test-question__image test-question__image--desktop" :src="imageSrc">
      <div class="test-question__wrapper">
        <TestCount
          class="test-question__count"
          :questions-number="questionsNumber"
        />
        <div class="test-question__text text-bold" v-html="question.text" />
        <img class="test-question__image test-question__image--mobile" :src="imageSrc">
        <TestAnswer
          v-for="(answer, index) in question.answers"
          :key="answer.title"
          :answer="answer"
          :isQuestionAnswered="isQuestionAnswered"
          :isAnswerChosen="isAnswerChosen(index)"
          @click="handleAnswer(answer, index)"
        />
        <div
          v-if="isQuestionAnswered"
          class="test-question__footer test-question__footer--desktop"
        >
          <img class="test-question__footer-logo" src="@/assets/images/home/logo.svg">
          <div class="test-question__footer-text" v-html="question.note" />
        </div>
        <div
          v-if="isQuestionAnswered"
          class="test-question__desktop-button"
          @click="goToNext"
        >
          дальше
          <svg
            class="test-question__desktop-button-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18.13 27.42"
          >
            <g>
              <g>
                <path fill="#538414" d="M1.35,0H7.82A1.7,1.7,0,0,1,9.31.7l8.33,11.57a2.34,2.34,0,0,1,0,2.88L9.29,26.68a1.59,1.59,0,0,1-1.47.74H1.4c-1.4,0-1.69-.94-1.1-1.77L8.42,14.23a.87.87,0,0,0,0-1L.3,1.77C-.29.94,0,0,1.35,0Z" />
              </g>
            </g>
          </svg>
        </div>
      </div>
    </CoreContainer>
    <div v-if="isQuestionAnswered" class="test-question__footer test-question__footer--mobile">
      <CoreContainer class="test-container__footer" style="position: relative;">
        <img class="test-question__footer-logo" src="@/assets/images/home/logo.svg">
        <div class="test-question__footer-text" v-html="question.note" />
        <CoreButton
          :width="140"
          class="test-question__button"
          @click="goToNext"
        >
          <span class="test-question__button-text">далее</span>
        </CoreButton>
      </CoreContainer>
    </div>
  </div>
</template>

<script>
import CoreButton from '@components/CoreButton';
import CoreContainer from '@components/CoreContainer';
import TestCount from '@components/test/TestCount.vue';
import TestAnswer from './TestAnswer.vue';

export default {
  name: 'TestQuestion',
  components: {
    TestCount,
    CoreContainer,
    TestAnswer,
    CoreButton,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    questionsNumber: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    answeredNumber: undefined,
  }),
  computed: {
    isQuestionAnswered() {
      return typeof (this.answeredNumber) === 'number';
    },
    imageSrc() {
      const imageName = this.isQuestionAnswered ? this.question.image : `${this.question.image}-answered`;
      // eslint-disable-next-line import/no-dynamic-require,global-require
      return require(`@assets/images/test/questions/${imageName}.png`);
    },
  },
  methods: {
    handleAnswer(answer, index) {
      if (!this.isQuestionAnswered) {
        this.answeredNumber = index;
        this.$emit('answer', answer.correct);
      }
    },
    goToNext() {
      this.$emit('next');
    },
    isAnswerChosen(index) {
      return this.answeredNumber === index;
    },
  },
};
</script>

<style scoped lang="scss">
  .test-question {
    //margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    &__container {
      display: flex;
      flex-direction: column;

      &-gutter {
        @media (max-width: 1052px) {
          padding-bottom: 110px;
        }
      }

      @media (min-width: 1052px) {
        flex-direction: row;
        height: 100%;
      }
      @media (min-width: 2000px) {
        transform: scale(1.8);
        transform-origin: center;
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: column;

      @media (min-width: 1052px) {
        width: 400px;
        margin-left: 34px;
      }
    }

    &__text {
      font-size: 16px;
      line-height: 19.2px;
      color: #424A0C;

      @media (min-width: 1052px) {
        margin-bottom: 45px;
      }
    }

    &__image {
      width: 100%;

      &--mobile {
        @media (min-width: 1052px) {
          display: none;
        }
      }

      &--desktop {
        display: none;

        @media (min-width: 1052px) {
          display: block;
        }
      }
    }

    &__button {
      margin-top: 20px;
    }

    &__button-text {
      font-size: 20px;
      color: #fff;
    }

    &__footer {
      &--mobile {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        min-width: 100vw;
        margin-top: 12px;
        padding: 20px 15px 120px 15px;
        background: #538414;

        @media (min-width: 1052px) {
          display: none;
        }

        .test-question__footer {
          &-logo {
            position: absolute;
            left: 0;
            width: 68px;
          }

          &-text {
            margin-left: 100px;
            color: #fff;
            font-size: 12px;
          }
        }
      }

      &--desktop {
        border-top: 1px solid #538414;
        border-bottom: 1px solid #538414;
        padding: 14px;
        margin-top: 12px;
        margin-bottom: 65px;
        display: none;

        @media (min-width: 1052px) {
          display: block;
        }

        .test-question__footer {
          &-logo {
            width: 68px;
            margin-bottom: 12px;
          }

          &-text {
            color: #538414;
            font-size: 12px;
          }
        }
      }
    }

    &__desktop-button {
      display: none;
      align-items: center;
      align-self: flex-end;
      cursor: pointer;
      margin-top: 20px;
      color: #538414;
      font-size: 12px;
      text-decoration: underline;

      @media (min-width: 1052px) {
        display: flex;
      }

      &-icon {
        margin-left: 10px;
        width: 18px;
      }
    }
  }
</style>
