<template xmlns="http://www.w3.org/1999/html">
  <teleport to="body">
    <transition name="fade">
      <div v-if="userDoesntScroll" ref="downPointer" class="down-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 60.6 101.1"
        >
          <path
            class="cls-1"
            d="M60.6,65.53V77.8a4.81,4.81,0,0,1-1.55,3.81L33.49,100a5.19,5.19,0,0,1-6.37,
              0L1.64,81.57A4.72,4.72,0,0,1,0,77.7V65.53c0-3.09,2.08-5.14,3.91-3.84L29.15,
              79.63a1.91,1.91,0,0,0,2.31,0L56.7,61.69C58.52,60.39,60.6,62.44,60.6,65.53Z"
          />
          <path
            class="cls-2"
            d="M60.6,34.88V47.16A4.77,4.77,0,0,1,59.05,51L33.49,69.37a5.19,5.19,0,0,1-6.37,
              0L1.64,50.92A4.69,4.69,0,0,1,0,47.05V34.88C0,31.79,2.08,29.74,3.91,31L29.15,
              49a1.91,1.91,0,0,0,2.31,0L56.7,31C58.52,29.74,60.6,31.79,60.6,34.88Z"
          />
          <path
            class="cls-3"
            d="M60.6,4.24V16.51a4.81,4.81,0,0,1-1.55,3.81L33.49,38.72a5.18,5.18,0,0,1-6.37,
              0L1.64,20.28A4.72,4.72,0,0,1,0,16.41V4.24C0,1.15,2.08-.9,3.91.4L29.15,18.34a1.91,
              1.91,0,0,0,2.31,0L56.7.4C58.52-.9,60.6,1.15,60.6,4.24Z"
          />
        </svg>
      </div>
    </transition>
  </teleport>

  <div class="board-1">
    РАБОТА НА ВЫРОСТ!
  </div>

  <div class="board-2">
    <div class="title text-bold">
      Заботимся <span class="stop-line-br">о бизнесе</span> <span class="stop-line-br">и окружающей</span> среде
    </div>
    <div class="content">
      <!--      <div class="text">-->
      <!--        Компания-->
      <!--        <span class="stop-line-br">НПАО «Сильвамо Корпорейшн</span> Рус» имеет-->
      <!--        <span class="stop-line-br">сертификат (FSC™C105866),</span>-->
      <!--        <br>-->
      <!--        <span class="stop-line-br">что подтверждает</span> стремления ответственного ведения-->
      <!--        лесного хозяйства.-->
      <!--      </div>-->
      <div class="more" @click="$m_openModal('certified')">
        <div class="link">
          <a>подробнее...</a>
        </div>
        <svg-pointer color="#7D8718" />
      </div>
    </div>
  </div>

  <div class="board-3 text-bold">
    Ценим лес!
  </div>

  <div class="board-4">
    <div class="text">
      Мы сохраняем ценные участки леса, <span class="nowrap">а также</span> рационально используем лесные ресурсы.
    </div>
    <div class="more" @click="$m_openModal('forest')">
      <div class="link">
        <a>подробнее...</a>
      </div>
      <svg-pointer color="#7D8718" />
    </div>
  </div>

  <div class="board-5 text-bold">
    Знай наших!
  </div>

  <div class="board-6">
    Мы сохраняем места обитания более 60 уникальных видов растений
    <span class="stop-line-br">и более</span>
    десятка популяций редких зверей <span class="stop-line-br">и птиц.</span>
  </div>

  <div class="board-7" @click="$m_openModal('rareAnimals')">
    <a>подробнее...</a>
    <svg-pointer />
  </div>

  <!--  <div class="two-equal-one">-->
  <!--    <div class="img-container">-->
  <!--      <img src="@/assets/images/home/2equal1.png" alt="2equal1">-->
  <!--    </div>-->
  <!--    <div class="text text-bold">-->
  <!--      Две купленные<br>-->
  <!--      коробки бумаги —<br>-->
  <!--      это одно новое<br>-->
  <!--      высаженное<br>-->
  <!--      дерево-->
  <!--    </div>-->
  <!--  </div>-->

  <div class="board-8 text-bold">
    На страже пожарной безопасности!
  </div>

  <div class="board-9">
    <div class="text">
      Мы постоянно патрулируем леса, создаём водоёмы <span class="stop-line-br">и разрывы</span>,
      <span class="stop-line-br">чтобы предотвратить</span>
      лесные пожары.
    </div>
    <div class="link">
      <a @click="$m_openModal('fire')">подробнее...</a>
      <svg-pointer />
    </div>
  </div>

  <div class="board-10 text-bold">
    У нас лес важных задач!
  </div>

  <div class="board-11">
    <div class="text">
      <div>Мы заботимся о посетителях наших лесов, организовывая места для отдыха.</div>
      <div>
        А также тесно сотрудничаем <span class="stop-line-br">с местными</span> жителями,
        помогая
        <span class="stop-line-br">им с трудоустройством </span><br>
        <span class="stop-line-br">и обеспечением</span> дровами.
      </div>
    </div>
    <div class="more" @click="$m_openModal('tasks')">
      <div class="link">
        <a>подробнее...</a>
      </div>
      <svg-pointer color="#7D8718" />
    </div>
  </div>

  <div class="board-13">
    <!--    <div class="text">-->
    <!--      Программа<br>-->
    <!--      по ответственному<br>-->
    <!--      сбору и утилизации<br>-->
    <!--      макулатуры в офисах <br>-->
    <!--      <b>Москвы</b> и <b class="stop-line-br">Санкт-Петербурга</b>.<br><br>-->
    <!--      <div class="link">-->
    <!--        <a href="https://dobrobum.svetocopy.com/" target="_blank">-->
    <!--          Узнайте больше!-->
    <!--        </a>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>

  <div class="board-14">
    <div class="title">
      Дерево – это множество разных продуктов. <span class="stop-line-br">И бумага –</span> лишь один из них
    </div>
    <div class="text">
      Ответственные производители бумаги используют ту часть дерева, которая остается при заготовке леса для других целей. Благодаря этому не происходит роста отходов в лесу.
    </div>
    <div class="more" @click="$m_openModal('treeUsage')">
      <div class="link">
        <a>подробнее...</a>
      </div>
      <svg-pointer color="#7D8718" />
    </div>
  </div>

  <div class="board-15">
    <div class="title">
      Ответственно заявляем!
    </div>
    <div class="text">
      Мы закупаем  балансовую часть дерева, необходимую <span class="stop-line-br">для</span> производства бумаги, только <span class="stop-line-br">у ответственных</span> поставщиков, которые осуществляют лесозаготовку <span class="stop-line-br">в соответствии</span> <span class="stop-line-br">с российскими</span> нормативами <span class="stop-line-br">и международными</span> стандартами.
    </div>
    <div class="link">
      <a @click="$m_openModal('responsibility')">подробнее...</a>
      <svg-pointer />
    </div>
  </div>

  <div class="board-16">
    <div class="title">
      Следим <br>за порядком!
    </div>
    <div class="text">
      Мы благодарны лесу <span class="stop-line-br">и заботимся</span> <span class="stop-line-br">о нем,</span> поддерживая чистоту <span class="stop-line-br">и порядок.</span>
    </div>
    <div class="link">
      <a @click="$m_openModal('care')">подробнее...</a>
      <svg-pointer />
    </div>
  </div>

<!--  <div class="board-12">-->
<!--    <div class="title text-bold">-->
<!--      Наша цель - повсюду ель!-->
<!--    </div>-->
<!--    <div class="text">-->
<!--      <div>-->
<!--        Наши цели по развитию леса до 2030 года - это:-->
<!--      </div>-->
<!--      <div>-->
<!--        Создавать здоровые богатые леса-->
<!--        по всему миру;-->
<!--        Содействовать достижению успеха сотрудников и местных сообществ;-->
<!--        Улучшать окружающую среду;-->
<!--        Ускорить переход к возобновляемым решениям.-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="more">-->
<!--      <div class="link">-->
<!--        <a href="">подробнее...</a>-->
<!--      </div>-->
<!--      <svg-pointer />-->
<!--    </div>-->
<!--  </div>-->
</template>
<script>
import { mapMutations } from 'vuex';
import SvgPointer from '@/components/home/SvgPointer.vue';

export default {
  name: 'StaticBoards',
  components: { SvgPointer },
  data: () => ({
    userDoesntScroll: false,
  }),
  methods: {
    ...mapMutations(['$m_openModal']),
  },
};
</script>

<style scoped lang="scss">
.two-equal-one {
  top: 2230px;
  left: 20px;

  z-index: 1 !important;
  width: 145px;
  color: #77582F;
  position: absolute;
  display: flex;
  flex-direction: column;
  @media (min-width: 1052px) {
    top: 2946px;
    left: 200px;

    width: 250px;
  }
  .img-container {
    width: 114px;
    @media (min-width: 1052px) {
      width: 200px;
    }
    img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }
  .text {
    color: #528413;
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
    @media (min-width: 1052px) {
      margin-top: 20px;
      max-width: 200px;

      font-size: 20px;
      line-height: 20px;

      span {
        display: block;
        width: 100%;
      }
    }
  }
}

.down-pointer {
  z-index: 88 !important;
  bottom: 0;
  left: 50%;

  height: fit-content;
  position: fixed;
  transform: translateX(-50%);
  transition: opacity .4s ease-in;
  svg {
    $duration: .7s;
    width: 60px;
    height: 100px;

    > * {
      fill: #fff;
      animation: pointer-down $duration ease infinite;
    }

    .cls-1 {
      animation-delay: 0s;
    }

    .cls-2 {
      animation-delay: $duration * 2 / 3;
    }

    .cls-3 {
      animation-delay: $duration * 1 / 3;
    }
  }

  @keyframes pointer-down {
    0% {
      fill: rgba(255, 255, 255, 0.5);
    }
    50% {
      fill: rgba(255, 255, 255, 0.3);
    }
    100% {
      fill: #fff;
    }
  }
}

.show-pointer {
  opacity: 1;
}

.board-1 {
  top: 1616px;
  left: 60px;

  color: #ffffff;
  position: absolute;
  font-family: 'FinchFrame2Bold', serif;
  font-style: normal;
  font-weight: bold;
  font-size: 54px;
  line-height: 64px;

  @media (min-width: 1052px) {
    top: 2214px;

    left: 50%;
    transform: translateX(-50%);
    font-size: 68px;
  }
}

.board-2 {
  top: 3290px;
  left: 90px;

  max-width: 244px;
  position: absolute;
  color: #7D8718;
  font-style: normal;
  font-weight: normal;

  @media (min-width: 1052px) {
    top: 4310px;
    left: calc(50% + 20px);
    transform: translateX(-50%);
  }

  .title {
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;

    @media (min-width: 1052px) {
      font-size: 22px;
      line-height: 27px;
    }
  }
  .content {
    max-width: 186px;
    display: flex;
    flex-direction: column;
    @media (min-width: 1052px) {
      max-width: 220px;
    }
    .text {
      margin-top: 20px;

      font-size: 16px;
      line-height: 19px;
      color: #000;
      @media (min-width: 1052px) {
        margin-top: 32px;

        font-size: 13px;
        line-height: 16px;
      }
    }
    .more {
      margin-top: 210px;
      cursor: pointer;

      font-size: 14px;
      line-height: 17px;
      align-self: flex-end;
      display: flex;
      @media (min-width: 1052px) {
        margin-top: 210px;

        font-size: 13px;
        line-height: 16px;
      }
      .link {
        margin-right: 7px;
        a {
          color: #7D8718;
          text-decoration: underline;
        }
      }
    }
  }
}

.board-3 {
  top: 4250px;
  left: 50%;

  transform: translateX(-50%);
  text-align: center;
  position: absolute;
  text-transform: uppercase;
  color: #7D8718;
  font-size: 42px;
  line-height: 51px;
  max-width: 158px;

  @media (min-width: 1052px) {
    top: 5466px;
  }
}

.board-4 {
  top: 4490px;
  left: 50%;

  transform: translateX(-50%);
  position: absolute;
  font-style: normal;
  font-weight: normal;
  max-width: 188px;
  display: flex;
  flex-direction: column;
  @media (min-width: 1052px) {
    top: 5720px;
    left: calc(50% + 10px);

    max-width: 166px;
  }

  .text {
    font-size: 16px;
    line-height: 19px;
    @media (min-width: 1052px) {
      font-size: 13px;
      line-height: 16px;
    }
  }
  .more {
    margin-top: 40px;
    cursor: pointer;

    align-self: flex-end;
    font-size: 14px;
    line-height: 17px;
    align-self: flex-end;
    display: flex;
    @media (min-width: 1052px) {
      margin-top: 23px;
      font-size: 13px;
    }
    .link {
      margin-right: 7px;
      a {
        color: #7D8718;
        text-decoration: underline;
      }
    }
  }
}

.board-5 {
  top: 5650px;
  left: 50%;

  transform: translateX(-50%);
  text-align: center;
  color: #ffffff;
  font-size: 42px;
  line-height: 40px;
  text-transform: uppercase;
  position: absolute;
  overflow: visible;
  @media (min-width: 1052px) {
    top: 7274px;
    font-size: 39px;
    max-width: 200px;
  }
}

.board-6 {
  top: 5850px;
  left: 50%;

  transform: translateX(-50%);
  position: absolute;
  color: #ffffff;
  max-width: 233px;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  @media (min-width: 1052px) {
    top: 7625px;

    font-size: 13px;
    line-height: 16px;
    max-width: 230px;
  }
}
.board-7 {
  top: 6010px;
  left: 50%;
  cursor: pointer;

  transform: translateX(-50%);
  position: absolute;
  color: #ffffff;
  font-style: normal;
  font-weight: normal;
  display: flex;
  align-items: center;
  @media (min-width: 1052px) {
    top: 7685px;
    left: calc(50% + 70px);
    z-index: 3;
    font-size: 13px;
    line-height: 16px;
  }
  a {
    margin-right: 5px;
    text-decoration: underline;
  }
}

.board-8 {
  top: 7350px;
  left: 64px;

  position: absolute;
  max-width: 205px;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 23px;
  line-height: 28px;
  text-align: center;

  @media (min-width: 1052px) {
    top: 9110px;
    left: calc(50% - 18px);
    transform: translateX(-50%);

    max-width: 235px;
    font-size: 26px;
    line-height: 32px;
  }
}

.board-9 {
  top: 7570px;
  left: 50%;

  transform: translateX(-50%);
  max-width: 172px;
  color: #ffffff;
  position: absolute;
  font-style: normal;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  @media (min-width: 1052px) {
    top: 9430px;
    left: calc(50% + 20px);

    max-width: 250px;
    font-size: 14px;
    line-height: 16px;
  }
  .link {
    margin-top: 20px;
    align-self: flex-end;

    display: flex;
    @media (min-width: 1052px) {
      margin-top: 32px;
      font-size: 13px;
    }
    a {
      margin-right: 8px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.board-10 {
  top: 8630px;
  left: 120px;

  max-width: 90px;
  text-transform: uppercase;
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  position: absolute;

  @media (min-width: 1052px) {
    top: 10600px;
    left: calc(50% - 20px);
    transform: translateX(-50%);

    font-size: 25px;
    line-height: 31px;
    max-width: 124px;
  }
}

.board-11 {
  top: 8820px;
  left: 50%;

  transform: translateX(-50%);
  position: absolute;
  color: #7D8718;
  max-width: 240px;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: 1052px) {
    top: 10840px;

    max-width: 220px;
  }
  .text {
    font-size: 16px;
    line-height: 19px;
    width: 100%;
    div:nth-child(2) {
      margin-top: 30px;
    }
    @media (min-width: 1052px) {
      font-size: 13px;
      line-height: 16px;
      div:nth-child(2) {
        margin-top: 18px;
      }
    }
  }
  .more {
    margin-top: 26px;
    margin-right: 20px;
    cursor: pointer;
    align-self: flex-end;
    display: flex;

    @media (min-width: 1052px) {
      margin-top: 22px;

      margin-right: 10px;
      font-size: 12px;
    }
    .link {
      margin-right: 8px;
      a {
        color: #7D8718;
        text-decoration: underline;
      }
    }
  }
}

.board-12 {
  top: 10170px;
  left: calc(50% - 18px);

  transform: translateX(-50%);
  position: absolute;
  color: #ffffff;
  width: 100%;
  max-width: 246px;
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: normal;
  @media (min-width: 1052px) {
    top: 12500px;
  }
  .title {
    font-size: 24px;
    text-align: center;
    line-height: 29px;
    text-transform: uppercase;
    @media (min-width: 1052px) {
      font-size: 30px;
      line-height: 37px;
    }
  }
  .text {
    margin-top: 40px;
    font-size: 16px;
    line-height: 19px;
    div:nth-child(2) {
      margin-top: 24px;
    }
    @media (min-width: 1052px) {
      margin-top: 20px;
      padding-left: 10px;

      font-size: 13px;
      line-height: 16px;
      div:nth-child(1) {
        max-width: 190px;
      }
      div:nth-child(2) {
        margin-top: 16px;

        max-width: 220px;
      }
    }
  }
  .more {
    margin-top: 46px;
    align-self: flex-end;
    display: flex;
    cursor: pointer;

    .link {
      margin-right: 8px;
      align-self: center;
      text-decoration: underline;
    }
    @media (min-width: 1052px) {
      margin-top: 18px;

      font-size: 13px;
    }
  }
}

.board-13 {
  top: 8348px;
  left: calc(50% + 8px);

  transform: translateX(-50%);
  position: absolute;
  color: #7D8718;
  max-width: 220px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 1052px) {
    top: 10510px;
    left: 50%;
    max-width: 288px;
  }

  .text {
    font-size: 16px;
    line-height: 19px;
    width: 100%;

    div:nth-child(2) {
      margin-top: 30px;
    }

    @media (min-width: 1052px) {
      div:nth-child(2) {
        margin-top: 18px;
      }
    }
  }
  .link a {
    color: #7D8718 !important;
  }
}

.board-14 {
  top: 2290px;
  left: 150px;
  z-index: 2;

  transform: translateX(-50%);
  position: absolute;
  color: #7D8718;
  max-width: 190px;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: 1052px) {
    top: 2820px;
    left: 450px;
    max-width: 190px;
  }

  .title {
    margin-bottom: 6px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;

    @media (min-width: 1052px) {
      font-size: 15px;
      margin-bottom: 14px;
      top: 2810px;
      left: 50%;
    }
  }
  .text {
    font-size: 14px;
    line-height: 18px;
    max-width: 170px;

    @media (min-width: 1052px) {
      font-size: 13px;
      line-height: 16px;
    }
  }
  .more {
    margin-top: 26px;
    margin-right: 20px;
    cursor: pointer;
    align-self: flex-start;
    display: flex;

    @media (min-width: 1052px) {
      margin-top: 22px;

      margin-right: 10px;
      font-size: 12px;
    }
    .link {
      margin-right: 8px;
      a {
        color: #7D8718;
        text-decoration: underline;
      }
    }
  }
}

.board-15 {
  position: absolute;
  top: 2845px;
  left: 46px;
  color: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 280px;

  @media (min-width: 1052px) {
    top: 3716px;
    left: calc(50% - 149px);
    max-width: 300px;
  }

  .title {
    text-align: center;
    font-weight: 700;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-size: 26px;
    line-height: 29px;

    @media (min-width: 1052px) {
      font-size: 25px;
      line-height: 29px;
      margin-bottom: 14px;
    }
  }
  .text {
    font-size: 13px;
    line-height: 16px;
    text-align: center;

    @media (min-width: 1052px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
  .link {
    margin-top: 20px;

    display: flex;
    @media (min-width: 1052px) {
      margin-top: 32px;
      font-size: 13px;
    }
    a {
      margin-right: 8px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.board-16 {
  position: absolute;
  top: 9450px;
  left: 50px;
  color: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 280px;

  @media (min-width: 1052px) {
    top: 11566px;
    left: calc(50% - 154px);
    max-width: 300px;
  }

  .title {
    text-align: center;
    font-weight: 700;
    margin-bottom: 40px;
    font-size: 36px;
    line-height: 38px;

    @media (min-width: 1052px) {
      margin-bottom: 40px;
      font-size: 36px;
      line-height: 38px;
    }
  }
  .text {
    font-size: 16px;
    line-height: 20px;
    @media (min-width: 1052px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
  .link {
    align-self: flex-end;
    margin-top: 20px;

    display: flex;
    @media (min-width: 1052px) {
      margin-top: 32px;
      font-size: 13px;
    }
    a {
      margin-right: 8px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
