<template>
  <CoreModal
    modal="treeUsage"
    image-src="/images/modals/treeUsage.jpg"
  >
    <template v-slot:title>
      Дерево – это множество разных продуктов. И бумага – лишь один из них.
    </template>
    <template v-slot:text>
      <div>
        Ствол дерева состоит <span class="nowrap">из разных частей,</span> каждая <span class="nowrap">из которых</span> нужна <span class="nowrap">для того или иного</span> производства. Например, <span class="nowrap">из наиболее</span> ценной части ствола, которая называется «пиловочник», производят  строительные материалы, мебель, предметы интерьера, спортивный инвентарь <span class="nowrap">и другие товары.</span> <span class="nowrap">У некоторых</span> пород пиловочник занимает более половины* ствола.
        <br>
        Еще одну небольшую часть дерева используют <span class="nowrap">для заготовки</span> дров <span class="nowrap">для отопления.</span>
        <br><br>
        И только <span class="nowrap">из трети ствола дерева,</span> так называемого баланса, производят бумагу. Таким образом, <span class="nowrap">на производство</span> бумаги идет меньшая часть дерева, <span class="nowrap">а не все дерево целиком.</span> Баланс <span class="nowrap">не подходит</span> <span class="nowrap">для создания</span> большинства товаров широкого потребления. Если производители бумаги <span class="nowrap">не будут</span> закупать балансовую часть, которая остается <span class="nowrap">при заготовке леса,</span> <span class="nowrap">это приведет</span> <span class="nowrap">к росту отходов</span> <span class="nowrap">в лесу.</span>
        <br><br>
        *Источник: средние показатели <span class="nowrap">по заготовке</span> древесины <span class="nowrap">в СЗФО</span> согласно внутренней оценке НПАО «Светогорский ЦБК» <span class="nowrap">и данным </span>
        <a href="https://www.fedstat.ru/" target="_blank">https://www.fedstat.ru/</a> <span class="nowrap">за 2019-2021гг.</span>
      </div>
    </template>
  </CoreModal>
</template>

<script>
import CoreModal from '@components/CoreModal';

export default {
  name: 'TreeUsageModal',
  components: { CoreModal },
};
</script>

<style scoped>
a,
a:link,
a:visited,
a:hover,
a:active {
  color: #538414;
}
</style>
