<template>
  <CoreContainer class="test-count text-bold">
    {{ testCount }}
  </CoreContainer>
</template>

<script>
import CoreContainer from '@components/CoreContainer';

export default {
  name: 'TestCount',
  components: { CoreContainer },
  props: {
    questionsNumber: {
      type: Object,
      required: true,
    },
  },
  computed: {
    testCount() {
      return `${this.questionsNumber.current + 1} / ${this.questionsNumber.total}`;
    },
  },
};
</script>

<style scoped lang="scss">
  .test-count {
    color: #7D8717;
    font-size: 14px;
    margin-top: 16px;
    text-align: left;
    align-items: flex-start;
    width: 100%;
    @media (min-width: 2000px) {
      transform: none;
      height: fit-content;
    }
  }
</style>
