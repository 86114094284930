<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="20px">
    <g>
      <circle
        class="cls-1"
        cx="10"
        cy="10"
        r="10"
        fill="#fff"
      />
      <rect
        class="cls-2"
        fill="#bf5708"
        x="8.7"
        y="3.24"
        width="2.61"
        height="13.51"
        transform="translate(-4.14 10) rotate(-45)"
      />
      <rect
        class="cls-2"
        fill="#bf5708"
        x="3.24"
        y="8.7"
        width="13.51"
        height="2.61"
        transform="translate(-4.14 10) rotate(-45)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TestErrorSvg',
};
</script>

<style scoped>

</style>
