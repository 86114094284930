<template>
  <CoreModal
    modal="certified"
    image-src="/images/modals/certified2.jpg"
  >
    <template v-slot:title>
      Заботимся о бизнесе и окружающей среде
    </template>
    <template v-slot:text>
      <div>
        SvetoCopy ECO - универсальная офисная бумага, <span class="nowrap">не привычного</span> всем белого, <span class="nowrap">а бежевого</span> цвета.
        <br><br>
        Бумага <span class="nowrap">SvetoCopy</span> ECO производится <span class="nowrap">без использования</span> хлора <span class="nowrap">и оптического</span> <span class="nowrap">отбеливателя,</span> что позволяет значительно снизить воздействие <span class="nowrap">на окружающую</span> среду. Кроме того, <span class="nowrap">с июня</span> 2023 года <span class="nowrap">на упаковке</span> пачки размещен знак «Лесной эталон», который подтверждает, <span class="nowrap">что при заготовке</span> древесины <span class="nowrap">не только</span> соблюдается лесное законодательство, <span class="nowrap">но и выполняются</span> дополнительные экологические <span class="nowrap">и социальные</span> требования.
        <br><br>
        Вы можете смело доверить бумаге SvetoCopy ECO цветные <span class="nowrap">и черно-белые</span> документы, графики, картинки <span class="nowrap">и тексты -</span> ведь <span class="nowrap">у нее</span> <span class="nowrap">есть все необходимые</span> характеристики <span class="nowrap">для качественной</span> печати.
      </div>
    </template>
  </CoreModal>
</template>

<script>
import CoreModal from '@components/CoreModal';

export default {
  name: 'CertifiedModal',
  components: {
    CoreModal,
  },
};
</script>

<style scoped>

</style>
