<template>
  <footer class="footer">
    <div class="footer-inner">
      <div class="buy item">
        <div class="icon" @click="routeToBuy">
          <img src="@/assets/images/home/footer/buy.svg" alt="buy">
        </div>
        <div class="text">купить бумагу</div>
      </div>
      <div class="svetocopy item">
        <div class="icon" @click="routeToSvetocopy">
          <img src="@/assets/images/home/footer/svetocopy.svg" alt="svetocopy">
        </div>
      </div>
      <!--      <div class="inter-paper item">-->
      <!--        <a class="icon" target="_blank" href="https://sylvamo.com/">-->
      <!--          <img src="@/assets/images/home/footer/sylvamo.svg" alt="sylvamo">-->
      <!--        </a>-->
      <!--      </div>-->
      <div class="vk item">
        <div class="icon" @click="routeToVK">
          <img src="@/assets/images/home/footer/vk.svg" alt="vk">
        </div>
      </div>
      <!--      <div class="instagram item">-->
      <!--        <a class="icon" target="_blank" href="https://www.instagram.com/svetocopycom/">-->
      <!--          <img src="@/assets/images/home/footer/instagram.svg" alt="instagram">-->
      <!--        </a>-->
      <!--      </div>-->
      <div class="feedback item">
        <div class="icon" @click="routeToFeedback">
          <img src="@/assets/images/home/footer/feedback.svg" alt="feedback">
        </div>
        <div class="text">обратная связь</div>
      </div>
    </div>

    <div class="footer-policy">
      <router-link to="privacy">Политика в отношении обработки Персональных данных</router-link>
    </div>

    <div class="scroll-top" @click="scrollToTop">
      <div class="icon">
        <img src="@/assets/images/home/footer/pointer-top.svg" alt="pointer-top">
      </div>
      <div class="text">НАВЕРХ</div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    routeToBuy() {
      window._txq = window._txq || [];

      _txq.push(['track', 'checkout']);
      window.open('https://sveto-paper.ru', '_blank').focus();
    },
    routeToSvetocopy() {
      window._txq = window._txq || [];
      _txq.push(['track', 'svetocopy']);
      window.open('http://www.svetocopy.com', '_blank').focus();
    },
    routeToVK() {
      window._txq = window._txq || [];
      _txq.push(['track', 'vk']);
      window.open('https://vk.com/svetocopycom', '_blank').focus();
    },
    routeToFeedback() {
      window._txq = window._txq || [];
      _txq.push(['track', 'feedback']);
      window.open('https://forms.yandex.ru/u/642fd3a743f74f01e578e341/', '_blank').focus();
    },
  },
};
</script>

<style scoped lang="scss">
.footer {
  margin-top: -1px;
  z-index: 999;

  flex: 1 1 100%;
  position: relative;
  background-color: #04955F;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100vw;
  @media (min-width: 2000px) {
    height: fit-content;
    min-height: 210px;
    padding-bottom: 30px;
  }

  .footer-policy {
    height: 50px;

    @media (max-width: 390px) {
      max-width: 300px;
      padding: 0 26px;
      margin-bottom: 30px;
    }

    @media (max-width: 750px) {
      max-width: 500px;
      padding: 0 26px;
      margin-bottom: 30px;
    }

    @media (min-width: 2000px) {
      margin-top: 50px;
    }
  }

  .footer-inner {
    padding: 40px 26px 30px;
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-between;
    @media (max-width: 750px) {
      max-width: 500px;
    }
    @media (max-width: 390px) {
      max-width: 300px;
    }
    @media (min-width: 2000px) {
      transform: scale(1.8);
    }
    .item {
      margin: 8px 4px 0;
      padding: 4px;

      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      min-width: 84px;
      min-height: 84px;
      box-shadow: 2px 2px 8px 2px rgba(0, 0, 0 , 0.3);
      transition: all 0.2s;
      img {
        height: auto;
        width: 100%;
        max-width: 100%;
      }
      &:hover {
        box-shadow: 2px 2px 4px 1.4px rgba(0, 0, 0 , 0.3);
      }
    }
    .buy,
    .feedback {
      z-index: 6;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #ffffff;
      .icon {
        width: 39px;
      }
      .text {
        margin-top: 6px;

        font-size: 11px;
      }
    }
    .svetocopy {
      z-index: 0;
      position: relative;
      .icon {
        width: 68px;
      }
    }
    .inter-paper {
      padding: 0;

      .icon {
        width: 108px;
      }
    }
    .vk {
      .icon {
        width: 44px;
      }
    }
    .instagram {
      .icon {
        width: 44px;
      }
    }
    .feedback {
      .icon {
        width: 36px;
      }
    }
  }
  .scroll-top {
    bottom: 30px;
    right: 26px;

    cursor: pointer;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 13px;
    color: #ffffff;
    transition: opacity .4s;
    @media (min-width: 2000px) {
      transform: scale(1.6);
      right: 40px;
      bottom: 40px;
    }
    .icon {
      width: 45px;
      img {
        height: auto;
        width: 100%;
        max-width: 100%;
      }
    }
    .text {
      margin-top: 6px;
    }
    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
