<template>
  <div ref="circle3" class="core-button circle-3 circle" :style="buttonStyle">
    <div ref="circle2" class="circle-2 circle">
      <div ref="circle1" class="circle-1 circle">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CoreButton',
  props: {
    width: {
      type: Number,
      default: 400,
    },
  },
  computed: {
    buttonStyle() {
      return {
        width: `${this.width}px`,
        height: `${this.width}px`,
      };
    },
  },
  mounted() {
    this.initAnimation();
  },
  methods: {
    initAnimation() {

    },
  },
};
</script>
<style lang="scss">
.core-button {
  cursor: pointer;
}
.circle {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
}
.circle-3 {
  background: #A2B928;
}
.circle-2 {
  background: #538414;
  width: 80%;
  height: 80%;
}
.circle-1 {
  background: #304C1E;
  width: 80%;
  height: 80%;
  position: relative;
}

.inactive-button {
  .circle-2 {
    width: 90%;
    height: 90%;
  }
  .circle-1 {
    width: 90%;
    height: 90%;
    position: relative;
  }
  &:hover {
    .circle-2 {
      width: 85%;
      height: 85%;
    }
    .circle-1 {
      width: 85%;
      height: 85%;
      position: relative;
    }
  }
}
</style>
