<template>
  <CoreModal
    modal="fire"
    image-src="/images/modals/fire.jpg"
  >
    <template v-slot:title>
      На страже пожарной безопасности!
    </template>
    <template v-slot:text>
      <div>
        Забота подразумевает не только развитие леса,
        <span class="stop-line-br">но и его охрану </span>
        <span class="stop-line-br">и защиту!</span><br><br>
        <span class="stop-line-br">Для обеспечения</span> пожарной безопасности наших лесных аренд <span class="stop-line-br">мы проводим</span> регулярные патрулирования территории, создаём пожарные водоёмы <span class="stop-line-br">и устраиваем</span> противопожарные минеральные полосы. Каждый посетитель проходит инструктаж, <span class="stop-line-br">и на территории</span> установлены предупредительные знаки.
      </div>
    </template>
  </CoreModal>
</template>

<script>
import CoreModal from '@components/CoreModal';

export default {
  name: 'FireModal',
  components: { CoreModal },
};
</script>

<style scoped>

</style>
