<template>
  <div
    v-if="state"
    class="core-modal"
    @click="$m_closeModal(modal)"
  >
    <div class="core-modal__wrapper">
      <div class="core-modal__container" @click.stop>
        <div class="core-modal__title">
          <slot name="title" />
        </div>
        <div class="core-modal__content">
          <div class="core-modal__image">
            <img v-if="imageSrc" :src="imageSrc" width="100%">
          </div>
          <div class="core-modal__text">
            <slot name="text" />
          </div>
        </div>
        <div class="core-modal__footer">
          <slot name="footer" />
          <div class="core-modal__button" @click="$m_closeModal(modal)">
            <svg class="core-modal__button-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.46 19">
              <g>
                <path
                  fill="#538414"
                  d="M11.13,0H7.28A1.49,1.49,0,0,0,6.09.49l-5.77,8a1.73,1.73,0,0,0,0,2l5.77,8A1.48,1.48,0,0,0,7.31,19h3.82c1,0,1.61-.65,1.2-1.23L6.71,9.87a.63.63,0,0,1,0-.74l5.62-7.9C12.74.65,12.1,0,11.13,0Z"
                />
              </g>
            </svg>
            назад
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'CoreModal',
  props: {
    modal: {
      type: String,
      required: true,
    },
    imageSrc: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    ...mapState(['modals']),
    state() {
      return this.modals[this.modal];
    },
  },
  methods: {
    ...mapMutations(['$m_closeModal']),
  },
};
</script>

<style scoped lang="scss">
  .core-modal {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
    @media (min-width: 2000px) {
      transform: scale(1.8);
    }

    &__wrapper {
      display: table-cell;
      vertical-align: middle;
    }

    &__container {
      display: flex;
      flex-direction: column;
      width: 100vw;
      height: 100vh;
      margin: 0 auto;
      padding: 45px 40px;
      background-color: #fff;
      overflow-y: auto;

      @media (min-width: 1052px) {
        width: 717px;
        height: auto;
        border-radius: 12px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      }
    }

    &__title {
      font-family: HeliosBold, 'sans-serif';
      font-weight: bold;
      font-size: 33px;
      line-height: 40px;
      color: #538414;
      margin-bottom: 20px;
    }

    &__content {
      display: flex;
      flex-direction: column;

      @media (min-width: 1052px) {
        flex-direction: row;
      }
    }

    &__image {
      min-width: 240px;
      width: auto;
      margin-bottom: 12px;
      margin-right: 20px;

      @media (min-width: 1052px) {
        margin-bottom: 0;
        width: 240px;
      }

      img {
        width: 100%;
      }
    }

    &__text {
      //font-family: Helios, 'sans-serif';
      font-size: 13px;
      line-height: 15.6px;
      color: #538414;
    }

    &__footer {
      display: flex;
      justify-content: flex-end;
      margin-top: 70px;

      @media (max-width: 1051px) {
        margin-bottom: 200px;
      }
    }

    &__button {
      display: flex;
      cursor: pointer;
      font-size: 12.44px;
      line-height: 19px;
      color: #538414;
      text-decoration: underline;

      &-icon {
        height: 19px;
        margin-right: 7px;
      }
    }
  }

</style>
