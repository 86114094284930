<template>
  <div class="ant-animation">
    <ant />
  </div>

  <!--  <div class="woodpecker-animation-1 sprite-animation">-->
  <!--    <img-->
  <!--      ref="woodpecker1"-->
  <!--      src="@/assets/images/home/animations/woodpecker_squeezed.png"-->
  <!--      alt="woodpecker1"-->
  <!--    >-->
  <!--  </div>-->
  <div class="woodpecker-animation-2 sprite-animation">
    <img
      ref="woodpecker2"
      src="@/assets/images/home/animations/woodpecker_squeezed.png"
      alt="woodpecker2"
    >
  </div>

  <div class="squirrel-animation sprite-animation">
    <img
      ref="squirrel"
      src="@/assets/images/home/animations/squirrel.png"
      alt="squirrel"
    >
  </div>

  <div class="eyes-animation-1 sprite-animation">
    <img
      ref="eyes1"
      src="@/assets/images/home/animations/eyes1.png"
      alt="eyes1"
    >
  </div>
  <div class="eyes-animation-2 sprite-animation">
    <img
      ref="eyes2"
      src="@/assets/images/home/animations/eyes2.png"
      alt="eyes2"
    >
  </div>
  <div class="eyes-animation-3 sprite-animation">
    <img
      ref="eyes3"
      src="@/assets/images/home/animations/eyes1.png"
      alt="eyes3"
    >
  </div>
  <div class="eyes-animation-4 sprite-animation">
    <img
      ref="eyes4"
      src="@/assets/images/home/animations/eyes2.png"
      alt="eyes4"
    >
  </div>
  <div class="eyes-animation-5 sprite-animation">
    <img
      ref="eyes5"
      src="@/assets/images/home/animations/eyes1.png"
      alt="eyes5"
    >
  </div>
  <div class="eyes-animation-6 sprite-animation">
    <img
      ref="eyes6"
      src="@/assets/images/home/animations/eyes2.png"
      alt="eyes6"
    >
  </div>
  <div class="eyes-animation-7 sprite-animation">
    <img
      ref="eyes7"
      src="@/assets/images/home/animations/eyes2.png"
      alt="eyes7"
    >
  </div>

  <div class="bird-animation-1 sprite-animation">
    <img
      ref="bird1"
      src="@/assets/images/home/animations/bird1.png"
      alt="bird1"
    >
  </div>
  <div class="bird-animation-2 sprite-animation">
    <img
      ref="bird2"
      src="@/assets/images/home/animations/bird1.png"
      alt="bird2"
    >
  </div>
  <div class="bird-animation-3 sprite-animation">
    <img
      ref="bird3"
      src="@/assets/images/home/animations/bird1.png"
      alt="bird3"
    >
  </div>

  <div class="bird-jump-animation sprite-animation">
    <img
      ref="birdJump"
      src="@/assets/images/home/animations/bird-jump_squeezed.png"
      alt="birdJump"
    >
  </div>

  <div class="mosquito-animation-1 sprite-animation">
    <img
      ref="mosquito1"
      src="@/assets/images/home/animations/mosquito.png"
      alt="mosquito1"
    >
  </div>
  <div class="mosquito-animation-2 sprite-animation">
    <img
      ref="mosquito2"
      src="@/assets/images/home/animations/mosquito.png"
      alt="mosquito2"
    >
  </div>

  <div class="nest-animation sprite-animation">
    <img
      ref="nest"
      src="@/assets/images/home/animations/nest.png"
      alt="nest"
    >
  </div>

  <div class="owl-animation sprite-animation">
    <img
      ref="owl"
      src="@/assets/images/home/animations/owl.png"
      alt="owl"
    >
  </div>

  <div class="otter-animation sprite-animation">
    <img
      ref="otter"
      src="@/assets/images/home/animations/otter.png"
      alt="otter"
    >
  </div>

  <div class="badger-animation sprite-animation">
    <img
      ref="badger"
      src="@/assets/images/home/animations/badger.png"
      alt="badger"
    >
  </div>

  <div class="snail-animation sprite-animation">
    <img
      ref="snail"
      src="@/assets/images/home/animations/snail.png"
      alt="snail"
    >
  </div>

  <div class="elk-animation sprite-animation">
    <img
      ref="elkEl"
      src="@/assets/images/home/animations/elk_squeezed.png"
      alt="elk"
    >
  </div>

</template>
<script>
import Ant from '@/components/home/animations/Ant.vue';

export default {
  name: 'SpriteAnimations',
  components: {
    Ant,
  },
};
</script>

<style lang="scss">
.woodpecker-animation-1 {
  top: 1084px;
  right: 103px;

  $width: 250px;
  $height: 500px;
  $scale: .3;
  width: $width * $scale;
  height: $height * $scale;
  @media (min-width: 1052px) {
    top: 1514px;
    right: 422px;
    $scale: .4;
    width: $width * $scale;
    height: $height * $scale;
  }
}

.woodpecker-animation-2 {
  top: 3789px;
  left: 87px;

  transform: scaleX(-1);
  $width: 250px;
  $height: 500px;
  $scale: .35;
  width: $width * $scale;
  height: $height * $scale;
  @media (min-width: 1052px) {
    top: 4710px;
    left: 406px;
    $scale: .4;
    width: $width * $scale;
    height: $height * $scale;
  }
}

.ant-animation {
  top: 3086px;
  right: -2px;

  z-index: 1;
  position: absolute;
  @media (min-width: 1052px) {
    top: 4024px;
    right: 270px;
  }
  .ant {
    $scale: 1.1;
    width: 157px * $scale;
    height: 176px * $scale;
    @media (min-width: 1052px) {
      $scale: 1.6;
      width: 157px * $scale;
      height: 176px * $scale;
    }
  }
}

.squirrel-animation {
  top: 6540px;
  left: 0;

  $width: 200px;
  $height: 200px;
  $scale: 1;
  opacity: 0;
  width: $width * $scale;
  height: $height * $scale;
  @media (min-width: 1052px) {
    $scale: 1.2;
    top: 8190px;
    width: $width * $scale;
    height: $height * $scale;
  }
}

$eyesWidth: 200px;
$eyesHeight: 200px;
.eyes-animation-1 {
  top: 418px;
  right: 133px;

  $scale: 0.11;
  width: $eyesWidth * $scale;
  height: $eyesHeight * $scale;

  display: none;
  @media (min-width: 1052px) {
    display: block;
    $scale: 0.15;
    top: 631px;
    right: 464px;
    width: $eyesWidth * $scale;
    height: $eyesHeight * $scale;
  }

}
.eyes-animation-2 {
  top: 5031px;
  right: 208px;

  $scale: 0.12;
  width: $eyesWidth * $scale;
  height: $eyesHeight * $scale;
  @media (min-width: 1052px) {
    top: 6472px;
    right: 574px;
    $scale: 0.16;
    width: $eyesWidth * $scale;
    height: $eyesHeight * $scale;
  }
}
.eyes-animation-3 {
  top: 5338px;
  right: 254px;

  $scale: 0.14;
  width: $eyesWidth * $scale;
  height: $eyesHeight * $scale;
  @media (min-width: 1052px) {
    $scale: 0.18;
    top: 6858px;
    right: 622px;
    width: $eyesWidth * $scale;
    height: $eyesHeight * $scale;
  }
}
.eyes-animation-4 {
  top: 5442px;
  right: 236px;

  $scale: 0.13;
  width: $eyesWidth * $scale;
  height: $eyesHeight * $scale;
  @media (min-width: 1052px) {
    $scale: 0.18;
    top: 6992px;
    right: 598px;
    width: $eyesWidth * $scale;
    height: $eyesHeight * $scale;
  }
}
.eyes-animation-5 {
  top: 5534px;
  right: 224px;

  $scale: 0.13;
  width: $eyesWidth * $scale;
  height: $eyesHeight * $scale;
  @media (min-width: 1052px) {
    $scale: 0.17;
    top: 7110px;
    right: 584px;
    width: $eyesWidth * $scale;
    height: $eyesHeight * $scale;
  }
}
.eyes-animation-6 {
  top: 5524px;
  right: 222px;

  $scale: 0.09;
  width: $eyesWidth * $scale;
  height: $eyesHeight * $scale;
  @media (min-width: 1052px) {
    $scale: 0.12;
    top: 7098px;
    right: 580px;
    width: $eyesWidth * $scale;
    height: $eyesHeight * $scale;
  }
}
.eyes-animation-7 {
  top: 5758px;
  right: 277px;

  $scale: 0.11;
  width: $eyesWidth * $scale;
  height: $eyesHeight * $scale;
  transform: rotate(-13deg);
  @media (min-width: 1052px) {
    $scale: 0.17;
    top: 7454px;
    right: 669px;
    width: $eyesWidth * $scale;
    height: $eyesHeight * $scale;
  }
}

$birdWidth1: 200px;
$birdHeight1: 180px;
.bird-animation-1 {
  z-index: 3 !important;
  top: 2361px;
  left: 0;

  $scale: 0.3;
  transform: scaleX(-1);
  width: $birdWidth1 * $scale;
  height: $birdHeight1 * $scale;
  @media (min-width: 1052px) {
    top: 3114px;
    $scale: .35;
    width: $birdWidth1 * $scale;
    height: $birdHeight1 * $scale;
  }
}
.bird-animation-2 {
  top: 3481px;
  left: 0;

  $scale: 0.32;
  width: $birdWidth1 * $scale;
  height: $birdHeight1 * $scale;
  @media (min-width: 1052px) {
    top: 4400px;
    $scale: .35;
    width: $birdWidth1 * $scale;
    height: $birdHeight1 * $scale;
  }
}

.bird-animation-3 {
  opacity: 0;
  @media (min-width: 1052px) {
    opacity: 1;
    top: 80px;
    left: 0;
    $scale: .2;
    width: $birdWidth1 * $scale;
    height: $birdHeight1 * $scale;
  }
}

.bird-jump-animation {
  top: 5190px;
  left: 25px;

  $scale: 0.25;
  $width: 600px;
  $height: 351px;
  width: $width * $scale;
  height: $height * $scale;
  @media (min-width: 1052px) {
    top: 6684px;
    left: 250px;
    $scale: .30;
    width: $width * $scale;
    height: $height * $scale;
  }
}

$mosquitoWidth: 160px;
$mosquitoHeight: 160px;
.mosquito-animation-1 {
  top: 5021px;
  right: 90px;

  $scale: 0.3;
  width: $mosquitoWidth * $scale;
  height: $mosquitoHeight * $scale;
  transform: scaleX(-1);
  animation: mosquito-movement 2s alternate ease-in-out infinite;
  @media (min-width: 1052px) {
    top: 6500px;
    right: 370px;
    $scale: 0.6;
    width: $mosquitoWidth * $scale;
    height: $mosquitoHeight * $scale;
  }
  @keyframes mosquito-movement {
    from {
      transform: translateX(0) scaleX(-1);
    }
    to {
      transform: translateX(20px) scaleX(-1);
    }
  }
}
.mosquito-animation-2 {
  top: 8276px;
  left: 20px;

  $scale: 0.4;
  width: $mosquitoWidth * $scale;
  height: $mosquitoHeight * $scale;
  animation: mosquito-movement2 2s alternate ease-in-out infinite;
  @media (min-width: 1052px) {
    top: 10074px;
    left: 230px;
    $scale: 0.6;
    width: $mosquitoWidth * $scale;
    height: $mosquitoHeight * $scale;
  }
  @keyframes mosquito-movement2 {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(20px);
    }
  }
}
.nest-animation {
  top: 5746px;
  right: 20px;

  z-index: 0 !important;
  $width: 250px;
  $height: 200px;
  $scale: 0.5;
  width: $width * $scale;
  height: $height * $scale;
  @media (min-width: 1052px) {
    top: 7420px;
    right: 300px;
    $scale: 0.8;
    width: $width * $scale;
    height: $height * $scale;
  }
}

.owl-animation {
  top: 6340px;
  left: 26px;

  $width: 200px;
  $height: 200px;
  $scale: 0.4;
  width: $width * $scale;
  height: $height * $scale;
  @media (min-width: 1052px) {
    top: 8110px;
    left: 298px;
    $scale: 0.5;
    width: $width * $scale;
    height: $height * $scale;
  }
}

.otter-animation {
  top: 6896px;
  right: 0px;

  $width: 300px;
  $height: 200px;
  $scale: 0.6;
  width: $width * $scale;
  height: $height * $scale;
  @media (min-width: 1052px) {
    top: 8630px;
    right: 280px;
    $scale: 0.7;
    width: $width * $scale;
    height: $height * $scale;
  }
}

.badger-animation {
  top: 7990px;
  right: 20px;

  z-index: 0 !important;
  $width: 400px;
  $height: 180px;
  $scale: 0.6;
  width: $width * $scale;
  height: $height * $scale;
  animation: badger-animation 3s ease-in infinite alternate;
  transform-origin: 30% 50%;
  @media (min-width: 1052px) {
    top: 9900px;
    right: 290px;
    $scale: 0.8;
    width: $width * $scale;
    height: $height * $scale;
  }
  @keyframes badger-animation {
    0% {
      transform: rotateY(-90deg);
    }
    40% {
      transform: rotateY(-90deg);
    }
    50% {
      transform: rotateY(0);
    }
    100% {
      transform: rotateY(0);
    }
  }
}

.snail-animation {
  top: 6196px;
  right: 77px;

  $width: 150px;
  $height: 120px;
  $scale: 0.7;
  width: $width * $scale;
  height: $height * $scale;
  transform: rotate(90deg) translateY(0);
  @media (min-width: 1052px) {
    top: 7930px;
    right: 380px;
    $scale: 0.8;
    width: $width * $scale;
    height: $height * $scale;
  }
}

.elk-animation {
  bottom: 0px;
  left: 54%;

  $width: 450px;
  $height: 350px;
  $scale: 0.8;
  width: $width * $scale;
  height: $height * $scale;
  transform: translateX(-50%);
  @media (min-width: 1052px) {
    $scale: 0.9;
    left: 52%;
    width: $width * $scale;
    height: $height * $scale;
  }
}
</style>
