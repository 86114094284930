<template>
  <svg class="test-close" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.13 21.32">
    <g>
      <g>
        <line
          class="test-close__line"
          x1="0.36"
          y1="0.35"
          x2="20.78"
          y2="20.97"
        />
        <line
          class="test-close__line"
          x1="20.78"
          y1="0.35"
          x2="0.36"
          y2="20.97"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TestClose',
};
</script>

<style scoped lang="scss">
  .test-close {
    z-index: 9999999;
    position: absolute;
    right: 3px;
    top: 3px;
    width: 20px;
    height: 20px;
    cursor: pointer;

    @media (min-width: 2000px) {
      transform: scale(1.8) translateX(-30px) translateY(30px);
    }

    &__line {
      fill: none;
      stroke: #241a0e;
      stroke-miterlimit: 10;
    }
  }
</style>
