<template>
  <CoreModal
    modal="grow"
    image-src="/images/modals/grow.jpg"
  >
    <template v-slot:title>
      Работа на вырост
    </template>
    <template v-slot:text>
      <div>
        Забота <span class="nowrap">о лесе</span> <span class="nowrap">и его развитие –</span> наше жизненное кредо! Мы высаживаем около <span class="nowrap">1 миллиона</span> сеянцев <span class="nowrap">в год!</span> <span class="nowrap">В среднем</span> это 3 сеянца на <span class="nowrap">1 вырубленное дерево.</span> Покупая бумагу SvetoCopy, <span class="nowrap">вы способствуете</span> восстановлению <span class="nowrap">и развитию</span> богатых <span class="nowrap">и здоровых лесов.</span> Звучит фантастически, <span class="nowrap">но это чистая правда!</span>
        <br><br>
        Деятельность нашей компании зависит <span class="nowrap">от устойчивого</span> развития лесных экосистем. Нам, как ответственному производителю, важно, чтобы лес был здоровым <span class="nowrap">и сильным,</span> <span class="nowrap">и мы активно занимаемся</span> его восстановлением <span class="nowrap">и сохранением</span> для будущих поколений.
        <br><br>
        Недостаточно просто высадить сеянец – нужно сделать все возможное, чтобы он превратился в здоровое молодое дерево.
        <br><br>
        На своих лесных арендах мы проводим выборочные рубки <span class="nowrap">ухода –</span> <span class="nowrap">то есть убираем</span> <span class="nowrap">и используем</span> <span class="nowrap">ту часть</span> ослабленных деревьев, которые мешают расти другим. Это похоже <span class="nowrap">на прополку</span> грядок <span class="nowrap">в огороде,</span> когда оставляют ценные растения <span class="nowrap">для дальнейшего выращивания,</span> <span class="nowrap">а сорняки</span> удаляют. Важно, что <span class="nowrap">мы внедряем</span> общепризнанные практики <span class="nowrap">по использованию</span> посадочного материала <span class="nowrap">с закрытой</span> корневой системой, которая повышает качество воспроизводства лесов.
      </div>
    </template>
  </CoreModal>
</template>

<script>
import CoreModal from '@components/CoreModal';

export default {
  name: 'GrowModal',
  components: { CoreModal },
};
</script>

<style scoped>

</style>
