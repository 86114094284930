<template>
  <div
    class="test-answer"
    :class="{
      'test-answer--success': isSuccess,
      'test-answer--failed': isFailed,
      'test-answer--answered': isQuestionAnswered
    }"
  >
    <div class="test-answer__left">
      <div class="test-answer__title text-bold" v-html="answer.title" />
      <div
        v-if="isAnswerChosen"
        class="test-answer__text"
        v-html="answer.text"
      />
    </div>
    <div class="test-answer__icon">
      <TestOkSvg v-if="isSuccess" />
      <TestErrorSvg v-if="isFailed" />
    </div>
  </div>
</template>

<script>
import TestOkSvg from '@components/test/svg/TestOkSvg.vue';
import TestErrorSvg from '@components/test/svg/TestErrorSvg.vue';

export default {
  name: 'TestAnswer',
  components: { TestErrorSvg, TestOkSvg },
  props: {
    answer: {
      type: Object,
      required: true,
    },
    isQuestionAnswered: {
      type: Boolean,
      default: false,
    },
    isAnswerChosen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isSuccess() {
      return this.isQuestionAnswered && this.answer.correct;
    },
    isFailed() {
      return this.isAnswerChosen && !this.answer.correct;
    },
  },
};
</script>

<style scoped lang="scss">
  .test-answer {
    width: 100%;
    min-height: 45px;
    padding: 14px;
    border: 1px solid #D9D2C0;
    border-radius: 23px;
    color: #7D8717;
    background: #fff;
    cursor: pointer;
    margin-bottom: 3px;
    display: flex;
    justify-content: space-between;

    &--success {
      background: #538414 !important;
      color: #fff !important;
    }

    &--failed {
      background: #C56724 !important;
      color: #fff !important;
    }

    &__title {
      font-size: 14px;
      line-height: 16.8px;
    }

    &__icon {
      width: 20px;
      flex-shrink: 0;
      align-self: center;
      margin-left: 10px;
    }

    &__text {
      font-size: 12px;
      line-height: 14.4px;
    }

    &:hover:not(.test-answer--answered) {
      background: #D9D2C0;
      color: #424A0C;
    }
  }
</style>
