<template>
  <CoreModal
    modal="forest"
    image-src="/images/modals/forest.jpg"
  >
    <template v-slot:title>
      Ценим лес!
    </template>
    <template v-slot:text>
      <div>
        Мы сохраняем ценные участки леса
        <span class="stop-line-br">и не проводим</span> там
        лесозаготовительные работы. Чтобы определить места
        <span class="stop-line-br">с высокой</span> природоохранной ценностью,
        мы консультируемся не только <span class="stop-line-br">с экспертами-биологами, </span>
        но и <span class="stop-line-br">с местными</span> сообществами.
        <br><br>
        Кроме того,
        <span class="stop-line-br">мы способствуем</span>
        рациональному <span class="stop-line-br">и эффективному</span>
        использованию лесных ресурсов.
        <br><br>
        Например, <span class="stop-line-br">для изготовления</span> бумаги мы закупаем щепу, которая является побочным продуктом лесопильных производств. <span class="stop-line-br">А отходы,</span> получаемые при производстве целлюлозы, <span class="stop-line-br">в том числе кору,</span> используем <span class="stop-line-br">на ТЭЦ</span> <span class="stop-line-br">для генерации</span> энергии <span class="stop-line-br">и тепла</span> <span class="stop-line-br">для самого</span> комбината <span class="stop-line-br">и города</span> <span class="stop-line-br">его расположения</span> Светогорска.
      </div>
    </template>
  </CoreModal>
</template>

<script>
import CoreModal from '@components/CoreModal';

export default {
  name: 'ForestModal',
  components: { CoreModal },
};
</script>

<style scoped>

</style>
