const questionsList = [
  {
    number: 1,
    text: 'Попробуйте догадаться, какого цвета практически все цветы, растущие <span class="nowrap">в еловом</span> лесу?',
    image: 'flowers',
    note: 'Мы высаживаем <span class="nowrap">3 новых</span> сеянца хвойных пород взамен <span class="nowrap">1 вырубленного</span> дерева <span class="nowrap">или более</span> <span class="nowrap">1 миллиона</span> сеянцев <span class="nowrap">в год</span>. Кроме того, мы используем посадочный материал <span class="nowrap">с закрытой</span> корневой системой, <span class="nowrap">чтобы ёлочки</span> лучше приживались.',
    answers: [
      {
        title: 'Зелёные',
        text: 'Сливаются <span class="nowrap">с елью</span> <span class="nowrap">и прячутся</span>? <span class="nowrap">К сожалению</span>, это неправильный ответ. Большинство цветов <span class="nowrap">— белые!</span> Просто насекомые плохо различают другие цвета <span class="nowrap">в тёмном</span> еловом лесу.',
        correct: false,
      },
      {
        title: 'Белые',
        text: 'Да! Просто <span class="nowrap">в еловом</span> лесу темно, <span class="nowrap">и насекомые</span>, которые опыляют их, плохо видят все цвета, <span class="nowrap">кроме белого</span>. Вот так естественный отбор!',
        correct: true,
      },
      {
        title: 'Цветы <span class="nowrap">не растут</span> <span class="nowrap">в еловом</span> лесу',
        text: 'Попались! Конечно же растут, <span class="nowrap">куда им</span> деваться. <span class="nowrap">И практически</span> <span class="nowrap">все они</span> белого цвета. <span class="nowrap">Потому что</span> насекомые, которые <span class="nowrap">опыляют их,</span> плохо видят <span class="nowrap">в тёмном</span> еловом лесу все цвета, кроме белого.',
        correct: false,
      },
    ],
  },
  {
    number: 2,
    text: 'Барсуки любят строить большие норы. <span class="nowrap">И обязательно</span> создают эту «‎специальную комнату»‎.',
    image: 'badger',
    note: 'Мы определяем леса <span class="nowrap">с высокой</span> природоохранной ценностью <span class="nowrap">и ограничиваем</span> свою деятельность <span class="nowrap">на этих</span> территориях. <span class="nowrap">Тем самым,</span> сохраняем места обитания барсуков <span class="nowrap">и других</span> видов животных.',
    answers: [
      {
        title: 'Уборная',
        text: 'Верно! Барсуки очень чистоплотные! Наверное, даже внутрь <span class="nowrap">не пустят</span> <span class="nowrap">с грязными</span> лапками.',
        correct: true,
      },
      {
        title: 'Гардеробная',
        text: 'Было бы забавно посмотреть, <span class="nowrap">как они</span> каждый год складывают старые шубки <span class="nowrap">на полочки</span>, <span class="nowrap">но, к сожалению,</span> они так <span class="nowrap">не делают</span>. <span class="nowrap">Зато строят</span> отдельные уборные.',
        correct: false,
      },
      {
        title: 'Кухня',
        text: 'Барсуки всеядны <span class="nowrap">и очень</span> любят поесть. <span class="nowrap">Но времени</span> сварить суп <span class="nowrap">у них нет</span>. <span class="nowrap">А значит,</span> <span class="nowrap">и кухня</span> <span class="nowrap">не нужна</span>. <span class="nowrap">Зато точно</span> нужна собственная уборная.',
        correct: false,
      },
    ],
  },
  {
    number: 3,
    text: '<span class="nowrap">В какое</span> дерево чаще, чем <span class="nowrap">в другие,</span> ударяет молния?',
    image: 'oak',
    note: 'Мы определяем леса <span class="nowrap">с высокой</span> природоохранной ценностью <span class="nowrap">и ограничиваем</span> свою деятельность <span class="nowrap">на этих</span> территориях.',
    answers: [
      {
        title: 'Грозовое дерево',
        text: 'Это вымышленное дерево. <span class="nowrap">Точнее, это вообще</span> <span class="nowrap">не дерево</span>. Это книга. <span class="nowrap">А правильный</span> <span class="nowrap">ответ — дуб,</span> который прорастает очень глубоко <span class="nowrap">и является</span> естественным громоотводом.',
        correct: false,
      },
      {
        title: 'Дуб обыкновенный',
        text: 'Абсолютно правильная догадка! Дуб прорастает очень глубоко вниз, <span class="nowrap">до воды</span>. Поэтому он — идеальный громоотвод.',
        correct: true,
      },
      {
        title: 'Береза металлическая',
        text: 'Зря она так называется. Ведь такого дерева <span class="nowrap">не существует</span>. <span class="nowrap">А правильный</span> <span class="nowrap">ответ — дуб</span> обыкновенный — отличный громоотвод, ведь прорастает очень глубоко вниз, <span class="nowrap">аж до воды!</span>',
        correct: false,
      },
    ],
  },
  {
    number: 4,
    text: 'Известно, <span class="nowrap">что муравьи</span> очень сильные насекомые. <span class="nowrap">А какой</span> вес смог бы поднять человек, если бы обладал такой же силой?',
    image: 'ant',
    note: 'Муравьи помогают бороться <span class="nowrap">с вредителями</span> леса. Поэтому <span class="nowrap">при лесозаготовках</span> мы огораживаем <span class="nowrap">и сохраняем</span> места обитания муравьев.',
    answers: [
      {
        title: 'От 5 до 10 тонн',
        text: 'К сожалению, <span class="nowrap">не угадали</span>. Только представьте себе <span class="nowrap">10 тонн</span>! Тогда можно было бы брать <span class="nowrap">на ручки</span> слона. <span class="nowrap">А поднять</span> человек смог бы примерно <span class="nowrap">от 2 до 5 тонн.</span>',
        correct: false,
      },
      {
        title: 'От 2 до 5 тонн',
        text: 'Правильно! Представляете, взяли <span class="nowrap">в руки</span> <span class="nowrap">по внедорожнику</span> <span class="nowrap">и пошли</span> вперед!',
        correct: true,
      },
      {
        title: 'Примерно 1 тонну',
        text: '<span class="nowrap">Не угадали</span>. Человек <span class="nowrap">с силой</span> муравья точно способен <span class="nowrap">на большее.</span> А именно поднять вес <span class="nowrap">от 2 до 5 тонн.</span>',
        correct: false,
      },
    ],
  },
  {
    number: 5,
    text: 'Ученые выяснили, <span class="nowrap">что комаров</span> особенно привлекает запах людей, которые недавно...',
    image: 'mosquito',
    note: 'Безопасность – это наша ключевая ценность. Мы ежегодно организовываем места <span class="nowrap">для безопасного</span> разведения костров, проводим аудиты <span class="nowrap">и обучение</span> <span class="nowrap">по безопасности,</span> обеспечиваем посетителей <span class="nowrap">и сотрудников</span> средствами индивидуальной защиты, <span class="nowrap">в том числе</span> <span class="nowrap">от насекомых.</span>',
    answers: [
      {
        title: 'Приняли душ',
        text: 'Ох уж эти манящие гели <span class="nowrap">для душа</span>! Нет, комары так <span class="nowrap">не думают.</span> <span class="nowrap">Зато без раздумий</span> прилетят <span class="nowrap">к тем,</span> кто недавно съел банан.',
        correct: false,
      },
      {
        title: 'Съели банан',
        text: 'Верно! Теперь <span class="nowrap">для комара</span> вы лакомый кусочек.',
        correct: true,
      },
      {
        title: 'Занимались спортом',
        text: 'Комары любят накачанные тела? Нет, им это неважно. <span class="nowrap">Но важно,</span> <span class="nowrap">кушали ли вы</span> недавно банан!\n',
        correct: false,
      },
    ],
  },
  {
    number: 6,
    text: 'Какое название получила улитка, которая хитро стреляет «‎стрелами любви»‎ <span class="nowrap">в своих</span> потенциальных партнеров?',
    image: 'snail',
    note: '<span class="nowrap">В наших</span> лесах мы устанавливаем специальные знаки <span class="nowrap">в местах</span> миграции земноводных <span class="nowrap">и ограничиваем</span> работы <span class="nowrap">на этих</span> территориях, чтобы <span class="nowrap">не мешать</span> их безопасному перемещению.',
    answers: [
      {
        title: 'Улитка-ниндзя',
        text: 'Правильно! <span class="nowrap">У них</span> даже есть специальные дротики, <span class="nowrap">как у настоящих</span> ниндзя!',
        correct: true,
      },
      {
        title: 'Улитка-купидон',
        text: 'Это вымышленная улитка, <span class="nowrap">к сожалению</span>. Понаблюдать <span class="nowrap">за такими</span> было бы очень интересно. <span class="nowrap">А правильный</span> ответ — улитка-ниндзя <span class="nowrap">со своими</span> любовными дротиками.',
        correct: false,
      },
      {
        title: 'Улитка-сердцеедка',
        text: 'Это вымышленная улитка. <span class="nowrap">А ее название</span> звучит скорее устрашающе, <span class="nowrap">чем мило.</span> <span class="nowrap">Зато действительно</span> существует улитка-ниндзя <span class="nowrap">со своими</span> любовными дротиками.',
        correct: false,
      },
    ],
  },
  {
    number: 7,
    text: 'Когда одна мышка видит, <span class="nowrap">как грустит</span> другая, <span class="nowrap">то она...</span>',
    image: 'mouse',
    note: 'Мыши обитают <span class="nowrap">в лесах</span>, <span class="nowrap">где развито</span> биоразнообразие. Мыши есть <span class="nowrap">и в наших</span> лесах, это подтверждает, что мы ведем свою деятельность <span class="nowrap">с заботой</span> <span class="nowrap">о лесе</span> <span class="nowrap">и его обитателях.</span>',
    answers: [
      {
        title: 'Тоже начинает грустить',
        text: 'Правильно! Мыши могут переживать чувства других мышек.',
        correct: true,
      },
      {
        title: 'Обнимает и успокаивает её',
        text: 'Это неправильный ответ. Но вы не грустите, будут еще другие вопросы. <span class="nowrap">А пока,</span> эта мышка тоже погрустит, вместе <span class="nowrap">с другой.</span>',
        correct: false,
      },
      {
        title: 'Приносит теплый плед и чашку какао',
        text: '<span class="nowrap">К сожалению,</span> нет. Скорее она бы принесла кусочек сыра! <span class="nowrap">И начала бы</span> грустить вместе <span class="nowrap">с другой</span> мышкой. Вот правильный ответ.',
        correct: false,
      },
    ],
  },
  {
    number: 8,
    text: 'Что <span class="nowrap">не умеет</span> дятел?',
    image: 'woodpecker',
    note: 'Мы сохраняем старовозрастные деревья – естественную кормовую базу <span class="nowrap">для птиц</span>. Кроме того, ежегодно устанавливаем кормушки <span class="nowrap">и скворечники</span> <span class="nowrap">для пернатых</span> обитателей леса.',
    answers: [
      {
        title: 'Есть, стоя <span class="nowrap">на горизонтальной</span> поверхности',
        text: 'Это правда, он даже спит вертикально!',
        correct: true,
      },
      {
        title: 'Слышать, <span class="nowrap">как движется</span> муравей',
        text: 'Вы <span class="nowrap">не поверите</span>, но это дятлы делать умеют. Именно так они понимают, <span class="nowrap">где сейчас</span> находится их еда. <span class="nowrap">А вот есть,</span> стоя <span class="nowrap">на горизонтальной</span> поверхности, они вообще <span class="nowrap">не умеют.</span>',
        correct: false,
      },
      {
        title: 'Выбирать, какое дерево долбить',
        text: 'Дятлы — санитары леса. Они пробивают только зараженные деревья. Они — большие молодцы, и вы — молодцы, но ответ неправильный. <span class="nowrap">А правильный</span> ответ — есть, стоя <span class="nowrap">на горизонтальной</span> поверхности.',
        correct: false,

      },
    ],
  },
  {
    number: 9,
    text: 'А вы можете представить себе, сколько орехов собирает <span class="nowrap">за тёплый</span> сезон белка-летяга?',
    image: 'squirrel',
    note: '<span class="nowrap">На территории</span> лесных аренд нашей компании мы выявляем и сохраняем места обитания более <span class="nowrap">10 видов</span> редких животных, среди которых есть <span class="nowrap">и белка-летяга.</span>',
    answers: [
      {
        title: 'Примерно 150—200',
        text: 'Нет. Этих орехов ей хватит, может быть, только на обед. <span class="nowrap">А вот</span> примерно 15 тысяч — <span class="nowrap">это то, что нужно!</span>',
        correct: false,
      },
      {
        title: 'Где-то 15 тысяч',
        text: 'Правильно! Где она столько находит?!',
        correct: true,
      },
      {
        title: 'Около 1 миллиона',
        text: 'Вы <span class="nowrap">не угадали</span>. Ведь ей бы пришлось собирать их <span class="nowrap">по всему</span> миру! <span class="nowrap">А как бы,</span> по-вашему, она смогла переплыть Атлантику? <span class="nowrap">Да и зачем?</span> Ведь ей хватает всего 15 тысяч орехов.',
        correct: false,
      },
    ],
  },
  {
    number: 10,
    text: '<span class="nowrap">У некоторых</span> выдр между жировыми складками есть кармашек. <span class="nowrap">А как они его</span> используют?',
    image: 'otter',
    note: 'Мы охраняем места обитания выдр <span class="nowrap">и других</span> животных <span class="nowrap">и не проводим</span> там работы <span class="nowrap">по лесозаготовкам.</span>',
    answers: [
      {
        title: 'Чтобы носить <span class="nowrap">в нём</span> припасы',
        text: '<span class="nowrap">К сожалению, нет.</span> Они <span class="nowrap">с радостью</span> сразу кушают все, что поймали прямо <span class="nowrap">в воде.</span> <span class="nowrap">А если добыча</span> большая <span class="nowrap">или её много</span>, то вытаскивают <span class="nowrap">её на берег.</span> Кармашек им нужен <span class="nowrap">для хранения</span> любимого камушка, представляете?!',
        correct: false,
      },
      {
        title: 'Чтобы носить <span class="nowrap">в нём</span> детёнышей',
        text: 'Неправильный ответ. Это вы <span class="nowrap">их с кенгуру</span> перепутали! <span class="nowrap">На самом деле,</span> <span class="nowrap">в кармашке</span> они носят один любимый камушек! <span class="nowrap">И могут</span> делать это всю жизнь!',
        correct: false,
      },
      {
        title: 'Чтобы носить <span class="nowrap">в нём</span> камушек',
        text: 'Правильный ответ! Если <span class="nowrap">им понравится</span> камушек, <span class="nowrap">то они даже</span> могут хранить его всю жизнь!',
        correct: true,
      },
    ],
  },
  {
    number: 11,
    text: 'Если вам понадобится источник запредельного количества витамина С, <span class="nowrap">что вы</span> выберете?',
    image: 'vitamin',
    note: 'Мы проводим выборочные рубки ухода – убираем часть ослабленных деревьев, которые мешают развиваться хвойным лесам. <span class="nowrap">Для производства</span> бумаги используется наименее ценная часть дерева, которая могла бы стать отходами лесозаготовок, <span class="nowrap">но мы</span> этого <span class="nowrap">не допускаем</span>!',
    answers: [
      {
        title: 'Лимон',
        text: 'Кислый фрукт <span class="nowrap">с витамином</span> С. Вроде бы всё верно, <span class="nowrap">но нет.</span> Надо выбирать еловую хвою! <span class="nowrap">В ней</span> намного больше необходимого витамина.',
        correct: false,
      },
      {
        title: 'Еловую хвою',
        text: 'Правильно! Здесь <span class="nowrap">в 6 раз</span> больше витамина С, <span class="nowrap">чем в лимоне</span>. <span class="nowrap">Из неё</span> даже делают целебные отвары.',
        correct: true,
      },
      {
        title: 'Еловую кору',
        text: 'Нет-нет. Кора несъедобная. <span class="nowrap">То ли дело</span> еловые иголочки! Здесь <span class="nowrap">в 6 раз</span> больше С витамина, чем <span class="nowrap">в лимоне!</span>',
        correct: false,
      },
    ],
  },
  {
    number: 12,
    text: 'Рога лося служат ему <span class="nowrap">для красоты</span> <span class="nowrap">и для защиты.</span> Догадаетесь, <span class="nowrap">для чего</span> ещё?',
    image: 'elk',
    note: 'Мы сохраняем леса <span class="nowrap">с высокой</span> природоохранной ценностью <span class="nowrap">и поддерживаем</span> разнообразие лесных экосистем.',
    answers: [
      {
        title: 'Для комфортного переноса добычи <span class="nowrap">до берлоги</span>',
        text: 'Неправильный ответ. Они питаются травянистыми растениями <span class="nowrap">и делают</span> это <span class="nowrap">на ходу.</span> <span class="nowrap">А рога</span> нужны <span class="nowrap">для лучшей</span> ориентации <span class="nowrap">в лесу</span><span class="nowrap">, ведь у них,</span> оказывается, <span class="nowrap">не очень</span> хорошее зрение.',
        correct: false,
      },
      {
        title: 'Чтобы лучше слышать <span class="nowrap">и ориентироваться</span> <span class="nowrap">в лесу</span>',
        text: 'Правильно! Оказывается, <span class="nowrap">у лосей</span> <span class="nowrap">не очень</span> хорошее зрение, поэтому <span class="nowrap">для лучшей</span> ориентации они используют рога, <span class="nowrap">как своеобразные</span> антенны.',
        correct: true,
      },
      {
        title: 'Чтобы можно было дотянуться <span class="nowrap">и почесать</span> себе спинку',
        text: 'К сожалению, ответ неправильный. <span class="nowrap">Но идея</span> хорошая. Как встретим его — сразу передадим. <span class="nowrap">А рога</span> нужны лосям, чтобы лучше ориентироваться <span class="nowrap">в лесу.</span> Они используют их, как своеобразные антенны.',
        correct: false,
      },
    ],
  },
];

export default questionsList;
