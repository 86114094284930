<template>
  <CoreContainer class="test-start">
    <img class="test-start__image" alt="" src="@/assets/images/test/drozdov/1_desktop.png">
    <div class="test-start__wrapper">
      <div class="test-start__title">
        <span class="text-bold">Николай Дроздов</span>
        и <span class="text-bold">SvetoCopy</span>
        проверяют вашу <span class="text-bold">ЛЕСОЭРУДИЦИЮ!</span>
      </div>
      <div class="test-start__text">
        Дорогие друзья!
        Как много интересных фактов вы знаете об удивительном мире леса и его обитателях?
      </div>
      <div class="test-start__text">
        Проверьте вашу лесоэрудицию с нашим увлекательным тестом!
      </div>

      <AnimatedButton ref="animatedButton" @click="$emit('start')">
        <span class="test-start__button-text">начать</span>
      </AnimatedButton>
    </div>
  </CoreContainer>
</template>

<script>
import AnimatedButton from '@components/AnimatedButton';
import CoreContainer from '../CoreContainer.vue';

export default {
  name: 'TestStart',
  components: { CoreContainer, AnimatedButton },
  mounted() {
    this.$refs.animatedButton.animate();
  },
};
</script>

<style scoped lang="scss">
  .test-start {
    display: flex;
    height: 100%;

    @media (min-width: 1052px) {
      flex-direction: row;
    }
    @media (min-width: 2000px) {
      transform: scale(1.8);
      height: fit-content;
      transform-origin: center;
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (min-width: 1052px) {
        align-items: flex-start;
        margin-left: 34px;
        min-width: 400px;
      }

      @media (max-width: 1051px) {
        padding-bottom: 120px;
      }
    }

    &__title {
      font-size: 20px;
      line-height: 24px;
      color: #304C1E;
      margin-bottom: 18px;

      @media (min-width: 1052px) {
        font-size: 26px;
        line-height: 31.16px;
        margin-bottom: 34px;
      }
    }

    &__text {
      font-size: 14px;
      line-height: 16.8px;
      color: #538414;
      margin-bottom: 18px;

      @media (min-width: 1052px) {
        font-size: 17px;
        line-height: 20.4px;
        margin-bottom: 22px;
      }
    }

    &__image {
      margin-top: 10px;
      width: 100%;

      @media (min-width: 1052px) {
        width: 600px;
      }
    }

    &__button-text {
      font-size: 20px;
      color: #fff;
    }
  }

  .animated-button {
    $buttonSize: 140px;
    width: $buttonSize * 1.5;
    height: $buttonSize * 1.5;
    @media (min-width: 1052px) {

    }
    .circle-1 {
      width: $buttonSize;
      height: $buttonSize;

      .circle-2 {
        $circle2Size: $buttonSize * 0.85;
        height: $circle2Size;
        width: $circle2Size;

        .circle-3 {
          $circle3Size: $buttonSize * 0.75;
          height: $circle3Size;
          width: $circle3Size;
        }
      }
    }
  }
</style>
