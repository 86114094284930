<template>
  <div class="test-social">
    <div class="fb share-link" @click="fbShare">
      <img src="@/assets/images/test/sharing/fb.svg" alt="fb">
    </div>
    <div class="vk share-link" @click="vkShare">
      <img src="@/assets/images/test/sharing/vk.svg" alt="vk">
    </div>
    <div class="social-text">
      поделиться
      <br>
      результатом
    </div>

    <!--    <a @click="fbShare">-->
    <!--      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.43 40.44">-->
    <!--        <g>-->
    <!--          <g>-->
    <!--            <path-->
    <!--              fill="#45658d"-->
    <!--              class="cls-1"-->
    <!--              d="M40.43,20.23A20.22,20.22,0,1,1,20.12,0,20.23,20.23,0,0,1,40.43,20.23Zm-18-3.47c0-.87,0-1.74,0-2.61a1.25,1.25,0,0,0-1.22-1.43,15.59,15.59,0,0,0-5.1,0,1.94,1.94,0,0,0-1.17.63c-.2.24-.21.4.15.47a1.66,1.66,0,0,1,1.4,1.58,13.6,13.6,0,0,1,.11,3.25,5.61,5.61,0,0,1-.34,1.77.67.67,0,0,1-1.09.34,4,4,0,0,1-.69-.62,8.77,8.77,0,0,1-1.26-1.89c-.69-1.32-1.38-2.65-2-4a1.37,1.37,0,0,0-1.3-1c-1.42,0-2.85,0-4.28.07-.8.05-1,.4-.74,1.12a43.65,43.65,0,0,0,6.52,11,10.83,10.83,0,0,0,10,4.09.9.9,0,0,0,.88-.86c0-.34.05-.68.09-1A2.82,2.82,0,0,1,23,26a.76.76,0,0,1,1.15-.17A6,6,0,0,1,25.44,27a15.77,15.77,0,0,0,1.75,1.81,3.35,3.35,0,0,0,2.35.84c.79,0,1.58,0,2.37,0,.64,0,1.28,0,1.91-.07a1,1,0,0,0,.89-1.44A4.52,4.52,0,0,0,34.05,27a19,19,0,0,0-2.1-2.3c-.52-.52-1.05-1-1.55-1.55a1.23,1.23,0,0,1-.2-1.69,8.54,8.54,0,0,1,.6-.88c.73-1,1.48-2,2.2-3a11.77,11.77,0,0,0,1.73-3c.3-.88,0-1.33-.94-1.32-1.6,0-3.2.07-4.8,0a1.32,1.32,0,0,0-1.45,1A23.44,23.44,0,0,1,25,19a7.9,7.9,0,0,1-1.35,1.59.65.65,0,0,1-1.11-.22,1.84,1.84,0,0,1-.17-.62A21.89,21.89,0,0,1,22.4,16.76Z"-->
    <!--            />-->
    <!--          </g>-->
    <!--        </g>-->
    <!--      </svg>-->
    <!--    </a>-->
  </div>
</template>

<script>
export default {
  name: 'TestSocial',
  props: {
    success: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    fbShare() {
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=http://dobro.svetocopy.com/redirect/fb/${this.success}`,
        'pop', 'width=600, height=400, scrollbars=no',
      );
    },
    vkShare() {
      window.open(
        `https://vk.com/share.php?noparse=true&url=http://dobro.svetocopy.com/&title=Проверьте%20вашу%20лесоэрудицию%20с%20тестом%20от%20Николая%20Дроздова%20и%20SvetoCopy&image=http://dobro.svetocopy.com/images/sharing/vk/${this.success}.png`,
        'pop', 'width=600, height=400, scrollbars=no',
      );
    },
  },
};
</script>

<style scoped lang="scss">
.test-social {
  margin-top: 8px;

  display: flex;
  @media (min-width: 1052px) {
    margin-top: 54px;
  }
  .share-link {
    margin-right: 12px;
    width: 40px;
    cursor: pointer;
    img {
      height: auto;
      width: 100%;
      max-width: 100%;
    }
  }
  .social-text {
    align-self: center;
    color: #666666;
    font-size: 12px;
    line-height: 14px;
  }
}
</style>
