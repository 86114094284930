<template>
  <div ref="testBtn" class="animated-button text-bold">
    <div ref="btnCircle1" class="circle-1">
      <div class="circle-2">
        <div class="circle-3">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap';

gsap.config({
  force3D: true,
});

export default {
  name: 'AnimatedButton',
  data: () => ({
    testButton: {
      circle1: {},
      circle2: {},
      circle3: {},
    },
    animations: [],
  }),
  mounted() {
  },
  methods: {
    animate() {
      const { btnCircle1: circle1 } = this.$refs;
      const circle2 = circle1.children[0];
      const circle3 = circle2.children[0];

      const tl1 = gsap.timeline({
        repeatDelay: 0.2,
        repeat: -1,
        defaults: {
          duration: 0.6,
          ease: 'power1.inOut',
        },
      });
      const tl2 = gsap.timeline({
        repeat: -1,
        defaults: {
          duration: 0.6,
          ease: 'power1.inOut',
        },
      });
      const tl3 = gsap.timeline({
        repeat: -1,
        defaults: {
          duration: 0.6,
          ease: 'power1.inOut',
        },
      });
      const circleSize1 = this.testButton.circle1.prevWidth ?? gsap.getProperty(circle1, 'width');
      this.testButton.circle1.prevWidth = circleSize1;
      const circleSize2 = this.testButton.circle2.prevWidth ?? gsap.getProperty(circle2, 'width');
      this.testButton.circle2.prevWidth = circleSize2;
      const circleSize3 = this.testButton.circle3.prevWidth ?? gsap.getProperty(circle3, 'width');
      this.testButton.circle3.prevWidth = circleSize3;

      const circleNewSize1 = circleSize1 * 0.95;
      const circleNewSize2 = circleSize2 * 1.05;
      const circleNewSize3 = circleSize3 * 1.05;

      tl3
        .fromTo(circle3,
          { width: circleSize3, height: circleSize3 },
          {
            width: circleNewSize3,
            height: circleNewSize3,
            duration: 0.3,
            ease: 'power1.out',
          })
        .to(circle3, {
          width: circleSize3,
          height: circleSize3,
          duration: 0.5,
          ease: 'slow(0.1, 0.1, false)',
        });

      tl2
        .fromTo(circle2,
          { width: circleSize2, height: circleSize2 },
          {
            width: circleNewSize2,
            height: circleNewSize2,
            duration: 0.6,
            ease: 'power1.out',
          })
        .to(circle2, {
          width: circleSize2,
          height: circleSize2,
          duration: 0.4,
          ease: 'power1.out',
        });

      tl1
        .fromTo(circle1,
          { width: circleSize1, height: circleSize1 },
          {
            width: circleNewSize1,
            height: circleNewSize1,
            duration: 0.3,
            ease: 'power1.out',
          })
        .to(circle1, {
          width: circleSize1,
          height: circleSize1,
          duration: 0.5,
          ease: 'slow(0.1, 0.1, false)',
        });

      return [tl1, tl2, tl3];
    },
  },
};
</script>

<style lang="scss">
.animated-button {
  font-size: 20px;
  line-height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  //$buttonSize: 194px;
  $buttonSize: 240px;
  width: $buttonSize * 1.5;
  height: $buttonSize * 1.5;
  color: #fff;
  transition: 0.2s ease-in;

  .circle-1 {
    cursor: pointer;
    border-radius: 50%;
    background-color: #A2B928;
    width: $buttonSize;
    height: $buttonSize;
    display: flex;
    justify-content: center;
    align-items: center;

    .circle-2 {
      border-radius: 50%;
      background-color: #538414;
      display: flex;
      justify-content: center;
      align-items: center;
      $circle2Size: $buttonSize * 0.85;
      height: $circle2Size;
      width: $circle2Size;

      .circle-3 {
        text-align: center;
        border-radius: 50%;
        background-color: #304C1E;
        $circle3Size: $buttonSize * 0.75;
        height: $circle3Size;
        width: $circle3Size;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>
