<template>
  <CoreModal
    modal="care"
    image-src="/images/modals/care.jpg"
  >
    <template v-slot:title>
      Следим за порядком!
    </template>
    <template v-slot:text>
      <div>
        Мы благодарны лесу <span class="nowrap">за те ресурсы,</span> которые <span class="nowrap">он нам дает,</span> <span class="nowrap">и заботимся о нем,</span> поддерживая чистоту <span class="nowrap">и порядок.</span> <span class="nowrap">Наша компания</span> регулярно проводит уборку мусора <span class="nowrap">на территории</span> своих лесных аренд <span class="nowrap">и ликвидирует</span> незаконные свалки, образовавшиеся рядом <span class="nowrap">с населёнными пунктами.</span> Поэтому наши леса чистые <span class="nowrap">и ухоженные,</span> похожи <span class="nowrap">на парки.</span> <span class="nowrap">И в них</span> так приятно проводить время.
      </div>
    </template>
  </CoreModal>
</template>

<script>
import CoreModal from '@components/CoreModal';

export default {
  name: 'CareModal',
  components: { CoreModal },
};
</script>

<style scoped>

</style>
