<template>
  <div class="equipment">
    <div class="equipment-inner">
      <div class="item-1">
        <img
          src="@/assets/images/home/animations/equipment1.svg"
          alt="equipment1"
          @click="rotateEquip($event, 'equipment1')"
        >
      </div>
      <div class="item-2">
        <img
          src="@/assets/images/home/animations/equipment2.svg"
          alt="equipment2"
          @click="rotateEquip($event, 'equipment2')"
        >
      </div>
      <div class="item-3">
        <img
          src="@/assets/images/home/animations/equipment3.svg"
          alt="equipment3"
          @click="rotateEquip($event, 'equipment3')"
        >
      </div>
      <div class="item-4">
        <img
          src="@/assets/images/home/animations/equipment4.svg"
          alt="equipment4"
          @click="rotateEquip($event, 'equipment4')"
        >
      </div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';

export default {
  name: 'Equipment',
  data: () => ({
    equipmentRotation: {
      equipment1: false,
      equipment2: false,
      equipment3: false,
      equipment4: false,
    },
  }),
  methods: {
    rotateEquip(event, key) {
      if (this.equipmentRotation[key]) return;
      this.equipmentRotation[key] = true;

      gsap.timeline()
        .to(event.target, {
          duration: 0.2,
          rotate: '20deg',
        })
        .to(event.target, {
          duration: 0.2,
          rotate: '-20deg',
        })
        .to(event.target, {
          duration: 0.2,
          rotate: '0deg',
          onComplete: () => {
            this.equipmentRotation[key] = false;
          },
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.equipment {
  top: 7670px;

  position: absolute;
  width: 100%;

  @media (min-width: 1052px) {
    top: 9480px;
    left: calc(50% - 10px);
    width: 370px;
    transform: translateX(-50%);
  }
  img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  .equipment-inner {
    position: relative;
    display: flex;
    align-items: flex-end;
    .item-1 {
      width: 65px;
      left: 50px;

      position: relative;

      @media (min-width: 1052px) {
        width: 71px;
      }
    }
    .item-2 {
      width: 66px;
      left: 40px;

      position: relative;
      @media (min-width: 1052px) {
        width: 72px;
      }
      img {
        transform-origin: 50% 0;
      }
    }
    .item-3 {
      width: 65px;
      left: 44px;

      z-index: 1;
      position: relative;
      @media (min-width: 1052px) {
        width: 72px;
      }
      img {
        transform-origin: 50% 0;
      }
    }
    .item-4 {
      width: 152px;
      left: 10px;
      top: 20px;

      z-index: 0;
      position: relative;
      @media (min-width: 1052px) {
        width: 166px;
      }
    }
  }

}
</style>
