<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="20px">
    <g>
      <circle
        class="cls-1"
        fill="#fff"
        cx="10"
        cy="10"
        r="10"
      />
      <rect
        class="cls-2"
        fill="#538414"
        x="5.74"
        y="6.59"
        width="2.61"
        height="9.83"
        transform="translate(-6.07 8.35) rotate(-45)"
      />
      <rect
        class="cls-2"
        fill="#538414"
        x="7.08"
        y="9.62"
        width="11.45"
        height="2.61"
        transform="translate(-3.98 12.25) rotate(-45)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TestOkSvg',
};
</script>

<style scoped>

</style>
