<template>
  <CoreContainer class="test-end">
    <div class="test-end__left">
      <img class="test-end__image" :src="imageSrc">
    </div>
    <div class="test-end__right">
      <div class="test-end__count text-bold" :style="{ backgroundColor: color }">
        {{ success }}/{{ total }}
      </div>
      <div
        class="test-end__result text-bold"
        :style="{ backgroundColor: color }"
        v-html="result"
      />
      <div class="test-end__text" :style="{ color }" v-html="text" />
      <div class="test-end__buttons">
        <div class="test-end__button test-end__button-left" @click="$emit('repeat')">
          пройти тест заново
        </div>
        <div class="test-end__button test-end__button-right" @click="$emit('close')">
          вернуться на сайт
        </div>
      </div>
      <TestSocial :success="success" />
    </div>
  </CoreContainer>
</template>

<script>
import CoreContainer from '@components/CoreContainer.vue';
import TestSocial from '@components/test/TestSocial.vue';

export default {
  name: 'TestEnd',
  components: { TestSocial, CoreContainer },
  props: {
    success: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    color() {
      if (this.success > 5) return '#538414';
      if (this.success > 1) return '#A96E20';
      return '#C56724';
    },
    result() {
      if (this.success === 12) return 'Вы – <br> всезнающий<br> головальдшнеп!';
      if (this.success > 8) return 'Вы – <br> выдающийся<br> бобрудит!';
      if (this.success > 5) return 'Вы – <br> настоящий<br> бурундучёный!';
      if (this.success > 1) return 'Вы – <br> большая<br> барсумница!';
      return 'Вы – начинающий комарстер!';
    },
    text() {
      if (this.success === 12) return '<span class="nowrap">У совы</span> закружилась голова <span class="nowrap">от ваших</span> знаний!';
      if (this.success > 8) return 'Белка перестала щелкать орешки, узнав <span class="nowrap">о ваших</span> познаниях!';
      if (this.success > 5) return '<span class="nowrap">У ясеня</span> перестали спрашивать, узнав ваш результат!';
      if (this.success > 1) return '<span class="nowrap">У ежа</span> иголки встали дыбом <span class="nowrap">от вашего</span> результата!';
      return 'Медведь вышел <span class="nowrap">из спячки</span>, узнав ваш результат!';
    },
    imageSrc() {
      let imageName = '2';
      if (this.success > 1) imageName = '5';
      if (this.success > 5) imageName = '3';
      if (this.success > 8) imageName = '4';
      if (this.success === 12) imageName = '6';

      // eslint-disable-next-line global-require,import/no-dynamic-require
      return require(`@assets/images/test/drozdov/${imageName}.png`);
    },
  },
};
</script>

<style scoped lang="scss">
  .test-end {
    padding-top: 12px;
    display: flex;
    flex-direction: column;
    padding-bottom: 150px;

    @media (min-width: 1052px) {
      flex-direction: row;
      height: 100%;
    }
    @media (max-height: 700px) and (min-width: 1052px) {
      transform: translateY(60px);
    }
    @media (min-width: 2000px) {
      height: fit-content;
      transform: scale(1.8) translateY(60px);
      transform-origin: center;
    }

    &__left {
      display: flex;
      flex-direction: column;

      @media (min-width: 1052px) {
        flex-direction: column-reverse;
      }
    }

    &__right {
      display: flex;
      flex-direction: column;

      @media (max-width: 1051px) {
        width: 100%;
      }

      @media (min-width: 1052px) {
        justify-content: center;
        align-items: center;
        width: 400px;
      }
    }

    &__title {
      color: #304C1E;
      font-size: 12px;
      line-height: 14.4px;
      text-align: center;
    }

    &__count {
      color: #fff;
      font-size: 24px;
      line-height: 28.8px;
      height: 82px;
      width: 82px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;

      @media (min-width: 1052px) {
        margin-bottom: 28px;
      }

      @media (max-width: 1051px) {
        position: absolute;
        margin-top: -94px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &__result {
      display: flex;
      justify-content: center;
      text-align: center;
      border-radius: 22px;
      color: #fff;
      font-size: 20px;
      line-height: 20px;
      padding: 8px;

      @media (min-width: 1052px) {
        width: 360px;
      }
    }

    &__image {
      width: 100%;
      @media (max-height: 700px) and (min-width: 1052px) {
        width: 80%;
      }
      @media (min-width: 2000px) {
        width: 80%;
      }
    }

    &__text {
      font-size: 14px;
      line-height: 16.8px;
      text-align: center;
      margin-top: 12px;
      margin-left: auto;
      margin-right: auto;
      max-width: 200px;

      @media (min-width: 1052px) {
        margin-bottom: 60px;
      }
    }

    &__buttons {
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
    }

    &__button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-size: 14px;
      cursor: pointer;

      &-left {
        background: #D9D2C0;
        color: #424A0C;
        margin-right: 2px;
        border-radius: 20px 0 0 20px;
      }

      &-right {
        background: #B0BE23;
        color: #424A0C;
        margin-left: 2px;
        border-radius: 0 20px 20px 0;
      }
    }
  }
</style>
