<template>
  <CoreModal
    modal="responsibility"
    image-src="/images/modals/responsibility.png"
  >
    <template v-slot:title>
      Ответственно заявляем
    </template>
    <template v-slot:text>
      <div>
        Только одна треть ствола дерева идет <span class="nowrap">на производство бумаги.</span> Поэтому <span class="nowrap">мы взаимодействуем</span> <span class="nowrap">с другими</span> производствами (мебели, например): закупаем <span class="nowrap">у них балансовую</span> часть дерева, которая <span class="nowrap">нам необходима.</span>
        <br><br>
        При этом, как ответственный производитель <span class="nowrap">мы сотрудничаем</span> только <span class="nowrap">с теми</span> поставщиками, которые осуществляют лесозаготовку <span class="nowrap">в соответствии</span> <span class="nowrap">с российскими</span> нормативами <span class="nowrap">и международными</span> стандартами.
        <br><br>
        <span class="nowrap">В рамках</span> наших работ <span class="nowrap">по лесозаготовкам</span> <span class="nowrap">мы берем</span> <span class="nowrap">на себя</span> дополнительные обязательства <span class="nowrap">в рамках</span> добровольной лесной сертификации.
        <br><br>
        Также мы получили сертификат «Лесной эталон», который подтверждает, что <span class="nowrap">при заготовке</span> древесины <span class="nowrap">не только соблюдается</span> лесное законодательство, <span class="nowrap">но и выполняются</span> дополнительные экологические <span class="nowrap">и социальные</span> требования.
      </div>
    </template>
  </CoreModal>
</template>

<script>
import CoreModal from '@components/CoreModal';

export default {
  name: 'ResponsibilityModal',
  components: { CoreModal },
};
</script>

<style scoped>

</style>
