<template>
  <div class="video-container-1" :class="containerClass">
    <video
      ref="video1"
      playsinline
      controls
    />
  </div>
  <!--  <div :class="{'hide-video': !plyrDisable}" class="video-container-1 mobile">-->
  <!--    <video-->
  <!--      ref="videoMobile1"-->
  <!--      playsinline-->
  <!--      controls-->
  <!--    />-->
  <!--  </div>-->

  <div class="video-buttons" :class="buttonsClass">
    <core-button
      v-for="(number, idx) in sources.length"
      :key="`video-player-${number}`"
      :width="buttonSize"
      :class="{ 'inactive-button': idx !== curSourceIdx}"
      @click="openVideo(idx)"
    >
      <div class="arrow-right" />
      <div class="number text-bold">{{ number }}</div>
    </core-button>
  </div>
</template>
<script>
import Plyr from 'plyr';
import 'plyr/src/sass/plyr.scss';
import CoreButton from '@/components/CoreButton.vue';

export default {
  name: 'VideoPlayers',
  components: {
    CoreButton,
  },
  props: {
    sources: {
      type: Array,
    },
    containerClass: String,
    buttonsClass: String,
  },
  data: () => ({
    plyrDisable: false,
    windowScale: 1,
    buttonSize: 108,
    playerOptions: {
      fullscreen: {
        iosNative: true,
      },
      controls: [
        'play-large',
        'play',
        'progress',
        'current-time',
        'mute',
        'volume',
        'airplay',
        'fullscreen',
      ],
      ratio: '16:9',
    },
    // sources: [
    //   {
    //     type: 'video',
    //     title: 'Example title',
    //     sources: [
    //       {
    //         src: 'jDIwPQpVwZ4',
    //         provider: 'youtube',
    //         size: 720,
    //       },
    //     ],
    //   },
    //   {
    //     type: 'video',
    //     title: 'Example title',
    //     sources: [
    //       {
    //         src: 'ZLF_X7jqgWI',
    //         provider: 'youtube',
    //         size: 720,
    //       },
    //     ],
    //   },
    //   {
    //     type: 'video',
    //     title: 'Example title',
    //     sources: [
    //       {
    //         src: 'njJoq1OkR80',
    //         provider: 'youtube',
    //         size: 720,
    //       },
    //     ],
    //   },
    // ],
    curSourceIdx: 0,
    player1: null,
  }),
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
    this.initPlyr();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    initPlyr() {
      this.player1 = new Plyr(this.$refs.video1, this.playerOptions);
      // this.player1.source = this.sources[0];
      this.openVideo();
    },
    openVideo(srcIdx = this.curSourceIdx) {
      this.player1.source = this.sources[srcIdx];
      // this.$refs.videoMobile1.src = this.sources[srcIdx].sources[0].src;
      this.curSourceIdx = srcIdx;
    },
    onResize() {
      const metaScale = document.head.querySelector('meta[name="viewport"]');

      if (metaScale.content !== 'width=device-width,initial-scale=1.0') this.windowScale = 0.8;
      else this.windowScale = 1;

      if ((window.innerWidth * this.windowScale) < 1052) {
        this.plyrDisable = true;
        return;
      }
      this.plyrDisable = false;
    },
  },
};
</script>
<style lang="scss">
.video-buttons {
  top: 10430px;

  position: absolute;
  display: flex;
  justify-content: space-around;
  width: 100%;
  @media (min-width: 1052px) {
    top: 13080px;
    left: calc(50% - 214px);
    width: 36%;
  }

  .core-button {
    .arrow-right {
      position: absolute;
      left: 20px;
      z-index: 0;
      width: 0;
      height: 0;
      border-top: 24px solid transparent;
      border-bottom: 24px solid transparent;

      border-left: 40px solid #538414;
      transition: 0.4s ease-in;
    }

    .number {
      position: relative;
      font-size: 22px;
      z-index: 2;
      color: #ffffff;
    }
  }
}

.inactive-button {
  .arrow-right {
    left: 30px !important;
    border-left: 38px solid #538414 !important;
  }

  &:hover {
    .arrow-right {
      left: 26px !important;
      border-left: 38px solid #538414 !important;
    }
  }
}

.video-container-1 {
  top: 10184px;
  left: 1px;

  z-index: 9999 !important;
  height: 208px;
  width: 100%;
  position: absolute;
  background-color: #000;
  display: flex;

  @media (min-width: 1052px) {
    top: 12668px;
    //height: 425px;
    height: 355px;
    width: 630px;
    left: calc(50% - 18px);
    transform: translateX(-50%);
  }

  > * {
    z-index: 100;
    width: 100%;
    height: 100%;
  }
}

.video-container-2 {
  top: 10590px;
  left: 0;

  width: 100%;
  height: 203px;
  z-index: 9999 !important;
  background-color: #000;
  position: absolute;

  @media (min-width: 1052px) {
    top: 12830px;
    left: 50%;
    transform: translateX(-50%);
    width: 690px;
    height: 390px;
  }

  > * {
    z-index: 100;
    position: relative;
    width: 100%;
    height: 100%;
    >* {
      z-index: 100;
    }
  }
}

.hide-video {
  z-index: 0 !important;
}

:root {
  --plyr-color-main: #B0BE23 !important;
}
</style>
