<template>
  <div
    id="home"
    ref="home"
    class="home"
  >
    <!--  modals  -->
    <Test />
    <GrowModal />
    <CertifiedModal />
    <ForestModal />
    <RareAnimalsModal />
    <FireModal />
    <TasksModal />
    <TreeUsageModal />
    <ResponsibilityModal />
    <CareModal />

    <CoreContainer ref="coreContainer">
      <div class="initial-info">
        <div class="logo">
          <img src="@/assets/images/home/logo.svg" alt="logo">
        </div>
        <div class="tagline">
          <img src="@/assets/images/home/tagline.svg" alt="tagline">
        </div>
        <div class="text-under-tagline">
          Ежегодно мы высаживаем около <span class="stop-line-br">1 миллиона</span> сеянцев, сохраняя места обитания редких
          видов растений, зверей и птиц в этом удивительном лесном мире
        </div>
      </div>

      <div ref="treeContainer" class="tree-container">

        <div class="tree">
          <img
            v-if="isDesktop"
            class="tree-desktop home-tree desktop"
            src="@/assets/images/home/tree_desktop-5.png"
            alt="tree"
          >
          <img
            v-else
            class="tree-mobile home-tree mobile"
            src="@/assets/images/home/tree_mobile-6.png"
            alt="tree"
          >
        </div>

        <div class="drozdov-right-text text-bold">
          ПРОВЕРЬТЕ ВАШУ <span>ЛЕСОЭРУДИЦИЮ</span> с Николаем Дроздовым!
        </div>

        <animated-button ref="testBtn" @click="openTest">
          <span>ПРОЙТИ<br>ТЕСТ</span>
        </animated-button>

        <!--        <animated-button ref="testBtn" @click="openInNewTab('https://dzen.svetocopy.com')">-->
        <!--          УЗНАТЬ<br>БОЛЬШЕ!-->
        <!--        </animated-button>-->

        <!--        <div class="top-mark">-->
        <!--          Реклама. НПАО «Светогорский ЦБК»-->
        <!--          <br>-->
        <!--          ИНН 4704012472, erid: 2Vtzqvr9FUC-->
        <!--        </div>-->

        <!--        <div class="top-logo">-->
        <!--          <img src="@/assets/images/home/logo_dzen.png" alt="logo">-->
        <!--        </div>-->

        <!--        <div class="top-text">-->
        <!--          Присоединяйтесь-->
        <!--          <br>-->
        <!--          <span class="stop-line-br"> к акции</span>-->
        <!--          <span class="stop-line-br"> в чат-боте </span> SvetoCopy, выполняйте веселые дзен-задания-->
        <!--          <span class="stop-line-br"> и выигрывайте</span>-->
        <!--          призы - -->
        <!--          <span class="stop-line-br"> с 18 сентября 2023</span> года.-->
        <!--        </div>-->

        <video-player
          video-src="-_vhQCmd64M"
          class="video-1"
          poster="/videos/top/previews/4.png"
        />
        <div class="video-text-1">
          Постичь SvetoДзен, почувствовать спокойствие и отпустить все споры, как сделали наши герои
        </div>
        <video-player
          video-src="ZSnwIL3Hci0"
          class="video-2"
          poster="/videos/top/previews/1.jpg"
        />
        <div class="video-text-2">
          Обрести гармонию и отключиться от забот под голос Николая Дроздова, чтобы постичь SvetoДзен
        </div>
        <!--        <video-player-->
        <!--          video-src="K9OZFPIH7Eo"-->
        <!--          class="video-3"-->
        <!--          poster="/videos/top/previews/3.jpg"-->
        <!--        />-->
        <video-players
          container-class="top-video-players"
          buttons-class="top-video-players-btns"
          :sources="topVideosSources"
        />
        <div class="video-text-3">
          Постичь SvetoДзен, почувствовать спокойствие <br>и отпустить все споры, как сделали наши герои
        </div>

        <sprite-animations ref="spriteAnimations" />
        <equipment />
        <static-boards ref="static" />

        <div class="videos-text">
          Давайте делать добрые дела не только на бумаге!
        </div>
        <video-players :sources="bottomVideosSources" />

        <nuts ref="nuts" />

        <div class="trees-circle">
          <div class="circle-inner">
            <trees-circle ref="treesCircle" />
            <div class="circle-text">
              <p>
                Мы высаживаем <span class="nowrap">3 новых дерева</span> взамен одного вырубленного, это примерно <span class="nowrap">1 миллион</span> сеянцев <span class="nowrap">в год!</span>
              </p>
              <div class="more" @click="$m_openModal('grow')">
                <div class="text">
                  <a>подробнее...</a>
                </div>
                <svg-pointer />
              </div>
            </div>
          </div>
        </div>

        <div class="hollow-nut">
          <div class="nut">
            <img
              ref="hollowNut"
              src="@/assets/images/home/animations/hollow-nut.svg"
              alt="hollowNut"
            >
          </div>
        </div>

        <div class="elk-nut-1">
          <img
            ref="elkNutEl"
            src="@/assets/images/home/animations/elk-nut.svg"
            alt="elk-nut"
          >
        </div>
      </div>
    </CoreContainer>
    <Footer />
  </div>
</template>

<script>
import Equipment from '@/components/home/Equipment.vue';
import SpriteAnimations from '@/components/home/SpriteAnimations.vue';
import StaticBoards from '@/components/home/StaticBoards.vue';
import CoreContainer from '@/components/CoreContainer.vue';
import { gsap } from 'gsap';
import TreesCircle from '@/components/home/animations/TreesCircle.vue';
import SvgPointer from '@/components/home/SvgPointer.vue';
import Test from '@/components/test/Test.vue';
import Footer from '@/components/home/Footer.vue';
import VideoPlayers from '@/components/home/VideoPlayers.vue';
import Nuts from '@/components/home/Nuts.vue';
import { mapGetters, mapMutations, mapState } from 'vuex';

import GrowModal from '@components/home/modals/GrowModal.vue';
import CertifiedModal from '@components/home/modals/CertifiedModal.vue';
import ForestModal from '@components/home/modals/ForestModal.vue';
import RareAnimalsModal from '@components/home/modals/RareAnimalsModal.vue';
import FireModal from '@components/home/modals/FireModal.vue';
import TasksModal from '@components/home/modals/TasksModal.vue';
import AnimatedButton from '@components/AnimatedButton.vue';
import VideoPlayer from '@components/home/VideoPlayer.vue';
import TreeUsageModal from '@components/home/modals/TreeUsageModal.vue';
import ResponsibilityModal from '@components/home/modals/ResponsibilityModal.vue';
import CareModal from '@components/home/modals/CareModal.vue';

gsap.config({
  force3D: true,
});

export default {
  name: 'Home',
  components: {
    CareModal,
    ResponsibilityModal,
    TreeUsageModal,
    VideoPlayer,
    TasksModal,
    FireModal,
    RareAnimalsModal,
    ForestModal,
    CertifiedModal,
    GrowModal,
    Footer,
    SvgPointer,
    TreesCircle,
    CoreContainer,
    Test,
    StaticBoards,
    Equipment,
    SpriteAnimations,
    VideoPlayers,
    Nuts,
    AnimatedButton,
  },
  data: () => ({
    metaScale: 'width=device-width,initial-scale=1.0',
    nuts: {
      elkNut: {
        isDropping: false,
        elkAnimated: false,
        elkAnimation: null,
      },
      hollowNut: {
        squirrelIsFlying: false,
        isDropping: false,
        visible: true,
      },
    },
    gsapAnimations: [],
    noScrollingTimer: null,
    noScrollingTime: 0,
    topVideosSources: [
      {
        type: 'video',
        title: 'Example title',
        sources: [
          {
            src: 'eabY_G4_xuo',
            provider: 'youtube',
            size: 720,
          },
        ],
      },
      {
        type: 'video',
        title: 'Example title',
        sources: [
          {
            src: 'K9OZFPIH7Eo',
            provider: 'youtube',
            size: 720,
          },
        ],
      },
    ],
    bottomVideosSources: [
      {
        type: 'video',
        title: 'Example title',
        sources: [
          {
            src: 'jDIwPQpVwZ4',
            provider: 'youtube',
            size: 720,
          },
        ],
      },
      {
        type: 'video',
        title: 'Example title',
        sources: [
          {
            src: 'ZLF_X7jqgWI',
            provider: 'youtube',
            size: 720,
          },
        ],
      },
      {
        type: 'video',
        title: 'Example title',
        sources: [
          {
            src: 'njJoq1OkR80',
            provider: 'youtube',
            size: 720,
          },
        ],
      },
    ],
  }),
  computed: {
    ...mapGetters(['isModalOpened', 'isDesktop']),
    ...mapState(['windowScale']),
  },
  mounted() {
    window.onresize = this.onResize;
    this.initAnimations();
    this.onResize();
  },
  methods: {
    ...mapMutations(['$m_openModal', '$m_closeModal', '$m_setWindowScale', '$m_setWindowWidth']),
    initAnimations() {
      document.body.onscroll = this.onScroll;
      const spriteRefs = this.$refs.spriteAnimations.$refs;

      this.nuts = {
        elkNut: {
          isDropping: false,
          elkAnimated: false,
          elkAnimation: null,
        },
        hollowNut: {
          squirrelIsFlying: false,
          isDropping: false,
          visible: true,
        },
      };

      this.initButton();

      // this.initSpriteAnimation(spriteRefs.woodpecker1, 74, 2.15);
      this.initSpriteAnimation(spriteRefs.woodpecker2, 74, 2.15);

      this.initSpriteAnimation(spriteRefs.eyes1, 60, 2);
      this.initSpriteAnimation(spriteRefs.eyes2, 60, 2);
      this.initSpriteAnimation(spriteRefs.eyes3, 60, 2, 0.7);
      this.initSpriteAnimation(spriteRefs.eyes4, 60, 2);
      this.initSpriteAnimation(spriteRefs.eyes5, 60, 2);
      this.initSpriteAnimation(spriteRefs.eyes6, 60, 2, 0.7);
      this.initSpriteAnimation(spriteRefs.eyes7, 60, 2);

      this.initSpriteAnimation(spriteRefs.bird1, 29, 1);
      this.initBirdFly(spriteRefs.bird1, 5);
      this.initSpriteAnimation(spriteRefs.bird2, 29, 1);
      this.initBirdFly(spriteRefs.bird2, 5, 'left');
      this.initSpriteAnimation(spriteRefs.bird3, 29, 1);
      this.initBirdFly(spriteRefs.bird3, 5, 'left');

      this.initSpriteAnimation(spriteRefs.mosquito1, 30, 1);
      this.initSpriteAnimation(spriteRefs.mosquito2, 30, 1);

      this.initSpriteAnimation(spriteRefs.nest, 60, 2);
      this.initSpriteAnimation(spriteRefs.owl, 60, 2);
      this.initSpriteAnimation(spriteRefs.otter, 60, 2);
      this.initSpriteAnimation(spriteRefs.badger, 44, 1.15);

      this.initSpriteAnimation(spriteRefs.snail, 90, 3);
      this.initSnailMovement(spriteRefs.snail);
      this.initSpriteAnimation(spriteRefs.birdJump, 60, 2);
    },
    getCoords(elem) {
      const box = elem.getBoundingClientRect();

      const { body } = document;
      const docEl = document.documentElement;

      const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
      const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

      const clientTop = docEl.clientTop || body.clientTop || 0;
      const clientLeft = docEl.clientLeft || body.clientLeft || 0;

      const top = box.top + scrollTop - clientTop;
      const left = box.left + scrollLeft - clientLeft;

      return {
        top: Math.round(top),
        left: Math.round(left),
      };
    },
    elementInViewport(el) {
      let top = el.offsetTop;
      let left = el.offsetLeft;
      const width = el.offsetWidth;
      const height = el.offsetHeight;

      while (el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
        left += el.offsetLeft;
      }

      return (
        top >= window.pageYOffset
        && left >= window.pageXOffset
        && (top + height) <= (window.pageYOffset + window.innerHeight)
        && (left + width) <= (window.pageXOffset + window.innerWidth)
      );
    },
    initButton() {
      this.gsapAnimations.push(...this.$refs.testBtn.animate());
    },
    initSpriteAnimation(el, frames, duration, delay = 0, repeat = -1) {
      const width = gsap.getProperty(el.parentNode, 'width');

      const tl = gsap.timeline({ repeat });
      for (let i = 0; i < frames; i++) {
        tl.set(el, {
          x: i * -width,
          delay,
          // onComplete: () => console.log(i),
        }, (i / (frames - 1)) * duration);
      }

      this.gsapAnimations.push(tl);
      return tl;
    },
    initTreesCircleAnimation() {
      const { treesCircle } = this.$refs;

      if (treesCircle.animated) return;
      const treesRect = treesCircle.$el.getBoundingClientRect();

      if (treesRect.top > window.innerHeight * 0.6) return;
      treesCircle.initAnimation();
    },
    animateSquirrel(squirrel, duration, nut) {
      if (nut.squirrelIsFlying || nut.isDropping) return;
      if (!nut.visible) return;

      const {
        hollowNut,
      } = this.$refs;

      const nutRect = hollowNut.getBoundingClientRect();

      if (nutRect.top > window.innerHeight * 0.5) return;

      const viewWidth = window.innerWidth;
      const fromX = -squirrel.parentNode.clientWidth
        - (viewWidth - this.$refs.treeContainer.clientWidth) / 2;
      const toX = this.$refs.treeContainer.clientWidth
        + (viewWidth - this.$refs.treeContainer.clientWidth) / 2;

      const spriteAnimation = this.initSpriteAnimation(
        this.$refs.spriteAnimations.$refs.squirrel, 16, 2, 0.7,
      );

      const tl = gsap.timeline();

      tl
        .set(squirrel.parentNode, { opacity: 1 })
        .fromTo(squirrel.parentNode,
          {
            x: fromX,
            y: -120,
          },
          {
            // eslint-disable-next-line no-mixed-operators
            x: toX / 2 - squirrel.parentNode.clientWidth * 3 / 2,
            y: 0,
            ease: 'none',
            duration: duration / 2,
          })
        .to(squirrel.parentNode,
          {
            x: toX,
            y: 120,
            ease: 'none',
            duration: duration / 2,
            onComplete: () => {
              nut.squirrelIsFlying = false;
              spriteAnimation.kill();
              tl.kill();
            },
          })
        .set(squirrel.parentNode, { opacity: 0 });

      nut.squirrelIsFlying = true;
    },
    initNutDropAnimation(nutKey, nutEl, duration, delay, xDirectionOffset = 0) {
      if (!this.nuts[nutKey]) {
        this.nuts[nutKey] = {
          isDropping: false,
          visible: true,
        };
      }
      const nut = this.nuts[nutKey];

      if (nut.isDropping) return;

      const nutRect = nutEl.getBoundingClientRect();
      const showIfOutOfViewport = () => {
        if (
          (nutRect.top + nutRect.height) > 0
          && nutRect.top < window.innerHeight
        ) return;

        gsap.to(nutEl, {
          opacity: 1,
        });
        nut.visible = true;
      };

      if (!nut.visible) {
        showIfOutOfViewport();
        return;
      }
      if (nutRect.top > window.innerHeight * 0.5 || nutRect.top < window.innerHeight * 0.2) return;

      nut.prevBottomCoords = gsap.getProperty(nutEl, 'bottom');
      nut.isDropping = true;

      const newBottomCoords = -(this.$refs.home.clientHeight
        - this.getCoords(nutEl).top + nutRect.height * 2);

      const prevLeft = gsap.getProperty(nutEl, 'left');

      gsap.to(nutEl, {
        bottom: newBottomCoords,
        left: prevLeft + xDirectionOffset,
        delay,
        duration,
        ease: 'sine.in',
        onComplete: () => {
          gsap.set(nutEl, {
            bottom: nut.prevBottomCoords,
            left: prevLeft,
            opacity: 0,
          });
          nut.visible = false;
          nut.isDropping = false;

          showIfOutOfViewport();
        },
      });
      gsap
        .to(nutEl, {
          rotate: '360deg',
          delay: 0.6 + delay,
          duration: 5,
          onComplete: () => {
            gsap.set(nutEl, { rotate: '0deg' });
          },
        });
    },
    initBirdFly(birdEl, duration, direction = 'right') {
      const viewWidth = window.innerWidth;
      let fromX = -birdEl.parentNode.clientWidth - (viewWidth - this.$refs.treeContainer.clientWidth) / 2;
      let toX = this.$refs.treeContainer.clientWidth
        + (viewWidth - this.$refs.treeContainer.clientWidth) / 2;

      if (direction === 'left') {
        [fromX, toX] = [toX, fromX];
      }

      const xAnimation = gsap.fromTo(birdEl.parentNode,
        { x: fromX },
        {
          repeat: -1,
          repeatDelay: 1,
          x: toX,
          ease: 'none',
          duration,
        });

      const yAnimation = gsap.fromTo(birdEl.parentNode,
        { y: 0 },
        {
          y: 80,
          repeat: -1,
          ease: 'power1.inOut',
          duration: 1,
          yoyo: true,
        });

      this.gsapAnimations.push(xAnimation, yAnimation);
    },
    initSnailMovement(snail) {
      const tl = gsap.timeline({ repeat: -1, defaults: { ease: 'none' } });

      tl
        .fromTo(snail.parentNode,
          { y: 0 },
          { y: -20, duration: 2 })
        .to(snail.parentNode, {
          y: 0,
          duration: 1,
        });

      this.gsapAnimations.push(tl);
    },
    initElkAnimation(left = '50%', hornYOffset = 0, xDropOffset = '20%') {
      const { elkNutEl, treeContainer } = this.$refs;
      const { elkEl } = this.$refs.spriteAnimations.$refs;
      const { elkNut } = this.nuts;

      if (elkNut.isDropping) return;

      const elkRect = elkEl.getBoundingClientRect();
      if (elkNut.elkAnimated) {
        if (elkRect.top < window.innerHeight) return;
        elkNut.elkAnimated = false;
        elkNut.elkAnimation.kill();
        gsap.set(elkEl, { x: 0 });
        return;
      }

      if (elkRect.top > window.innerHeight * 0.8) return;

      const tl = gsap.timeline();
      elkNut.isDropping = true;

      let multiplier1 = 1;
      let multiplier2 = 1;
      if (window.innerWidth < 360) {
        multiplier1 = 1.6;
        multiplier2 = 0.88;
      } else if (window.innerWidth < 2000) {
        multiplier1 = 1.6;
        multiplier2 = 1;
      } else {
        multiplier1 = 1.6;
      }

      tl
        .set(elkNutEl.parentNode, { opacity: 0 })
        .set(elkNutEl.parentNode, {
          opacity: 1,
          bottom: window.innerHeight,
          left,
        })
        .to(elkNutEl.parentNode, {
          bottom: 276 * multiplier2,
          ease: 'sine',
          duration: 0.5,

        })
        .to(elkNutEl.parentNode, {
          bottom: window.innerHeight * 0.3 * multiplier1,
          left: `${parseInt(left, 10) + parseInt(xDropOffset, 10) / 2}%`,
          ease: 'power1.out',
          duration: 0.2,
          onComplete: () => {
            gsap.fromTo(elkNutEl,
              {
                rotation: '0deg',
              },
              {
                rotation: '120deg',
                duration: 0.8,
              });
          },
        })
        .to(elkNutEl.parentNode, {
          bottom: 0,
          left: `${parseInt(left, 10) + parseInt(xDropOffset, 10)}%`,
          ease: 'power1.in',
          duration: 0.4,
          onComplete: () => {
            elkNut.isDropping = false;
            elkNut.elkAnimation = this.initSpriteAnimation(elkEl, 105, 3.15);
            elkNut.elkAnimated = true;
          },
        });

      this.gsapAnimations.push(tl);
    },
    showDownPointer() {
      if (this.noScrollingTimer === null) {
        const maxNoScrollTime = 2;

        this.noScrollingTimer = setInterval(() => {
          this.noScrollingTime++;
          console.log('Let`s scroll, bro');
          if (this.noScrollingTime < maxNoScrollTime) return;

          clearInterval(this.noScrollingTimer);
          this.noScrollingTimer = null;

          // move pointer
          const bodyHeight = gsap.getProperty(document.body, 'height');
          const pointerEl = this.$refs.static.$refs.downPointer;

          let hidePointerThreshold = 2000;
          if (window.innerWidth < 370) {
          } else if (window.innerWidth < 1052) {
          } else if (window.innerWidth < 2000) {
          } else {
            hidePointerThreshold = 3000;
          }

          if ((bodyHeight - window.scrollY) < hidePointerThreshold) return;

          this.$refs.static.userDoesntScroll = true;
        }, 1000);
      } else {
        clearInterval(this.noScrollingTimer);
        this.noScrollingTimer = null;
        this.noScrollingTime = 0;
        // hide arrow
        this.$refs.static.userDoesntScroll = false;
        setTimeout(() => this.showDownPointer(), 0);
      }
    },
    onScroll() {
      // this.initTreesCircleAnimation();
      this.initElkAnimation('45%', 8);

      const nutsNode = this.$refs.nuts.$refs;
      this.initNutDropAnimation('nut1', nutsNode.nut1, 4, 0.4, -200);
      this.initNutDropAnimation('nut2', nutsNode.nut2, 4, 0.4, 400);
      //
      this.animateSquirrel(this.$refs.spriteAnimations.$refs.squirrel, 3, this.nuts.hollowNut);
      this.initNutDropAnimation(this.nuts.hollowNut, this.$refs.hollowNut.parentNode, 5, 1.5);
      //
      this.showDownPointer();
    },
    removeGSAPAnimations() {
      this.gsapAnimations.forEach(animation => {
        animation.kill();
      });
      this.gsapAnimations = [];
    },
    rescaleContent() {
      const metaScale = document.head.querySelector('meta[name="viewport"]');
      const windowWidth = (window.innerWidth * this.windowScale);
      this.$m_setWindowWidth(window.innerWidth);

      let content = 'width=device-width,initial-scale=1.0';
      const appTranslateX = 0;

      if (windowWidth >= 2560) {
        // content = 'width=device-width, initial-scale=2.3, maximum-scale=2.3, user-scalable=0';
        // this.windowScale = 2.3;
        // appTranslateX = -720;
      } else if (windowWidth >= 2400) {
        // content = 'width=device-width, initial-scale=2.3, maximum-scale=2.3, user-scalable=0';
        // this.windowScale = 2.3;
        // appTranslateX = -660;
      } else if (windowWidth >= 2000) {
        // content = 'width=device-width, initial-scale=1.5, maximum-scale=1.5, user-scalable=0';
        // this.windowScale = 1.5;
        // appTranslateX = -340;
      } else if (windowWidth > 370) {
        content = 'width=device-width,initial-scale=1.0';
        this.$m_setWindowScale(1);
      } else {
        content = 'width=device-width, initial-scale=0.8, maximum-scale=0.8, user-scalable=0';
        this.$m_setWindowScale(0.8);
      }

      document.body.querySelector('#app').style.transform = `translateX(${appTranslateX}px)`;
      metaScale.content = content;
    },
    onResize() {
      this.removeGSAPAnimations();
      this.initAnimations();

      this.rescaleContent();
      document.body.querySelector('#app').classList.height = `${this.$refs.home.clientHeigth}px`;
    },
    openTest() {
      this.$m_openModal('test');
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'GA_Event',
        eventCategory: 'button',
        eventAction: 'clickStart',
      });
    },
    openInNewTab(url) {
      window._txq = window._txq || [];

      _txq.push(['track', 'VK']);

      window.open(url, '_blank').focus();
    },
  },
};
</script>

<style lang="scss">
.home {
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  flex-wrap: wrap;
  background-size: cover;
  max-width: 100vw;
  z-index: 2;
  //overflow-y: auto;
  overflow: hidden;
  //height: fit-content !important;
  //max-height: 11748px !important;
  height: 100%;
  width: 100%;
  .core-container {
    .initial-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 1;
      @media (min-width: 1052px) {
        position: absolute;
        //left: 40px;
        left: calc(50% - 360px);
        transform: translateX(-50%);
      }
      .logo {
        padding-top: 20px;
        width: 178px;
        @media (min-width: 1052px) {
          padding-top: 40px;

          width: 146px;
        }
        img {
          width: 100%;
          max-width: 100%;
          height: auto;
        }
      }

      .tagline {
        width: 290px;
        @media (min-width: 1052px) {
          width: 260px;
        }
        img {
          width: 100%;
          max-width: 100%;
          height: auto;
        }
      }

      .text-under-tagline {
        margin-top: 20px;

        max-width: 320px;
        font-size: 17px;
        line-height: 21px;
        color: #000;
        @media (min-width: 1052px) {
          color: #304C1E;
          max-width: 190px;
          font-size: 14px;
          line-height: 17px;
        }
        span {
          white-space:nowrap;
        }
      }
    }

    .tree-container {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      > * {
        z-index: 2;
      }

      .tree {
        z-index: 1;
        transform: translateX(65px);
        min-width: 1000px;

        img {
          display: block;
          max-width: 100%;
          width: 100%;
          height: auto;
        }
        .tree-mobile {}
        .tree-desktop {
          display: none;
        }
        @media (min-width: 1052px) {
          padding-top: 90px;
          transform: translateX(65px);
          min-width: 1450px;
          .tree-mobile {
            display: none;
          }
          .tree-desktop {
            display: block;
          }
        }
      }

      .roscha {
        top: 74px;
        right: 10px;
        position: absolute;
        max-width: 140px;

        img {
          width: 100%;
        }

        @media (min-width: 1052px) {
          top: 190px;
          right: 220px;
          max-width: 220px;
          font-size: 21px;
          line-height: 22px;
        }
      }

      .game-button {
        top: 296px;
        right: -190px;
        position: absolute;
        transform: translate(-50%, -50%);

        @media (min-width: 1052px) {
          top: 480px;
          right: 20px;
        }
      }

      .test-button {
        top: 1180px;
        left: 90px;
        position: absolute;
        transform: translateX(-50%);

        @media (min-width: 1052px) {
          top: 1630px;
          left: 320px;
        }
      }

      .drozdov-right-text {
        top: 130px;
        right: -10px;

        text-align: start;
        position: absolute;
        color: #538414;
        font-size: 16.6px;
        line-height: 19.2px;
        max-width: 160px;
        span {
          color: #304C1E;
        }
        @media (min-width: 1052px) {
          top: 240px;
          right: 210px;
          font-size: 21px;
          line-height: 22px;
        }
      }

      .top-mark {
        position: absolute;
        top: 494px;
        left: 152px;
        font-size: 8px;
        color: #666e7c;

        @media (min-width: 1052px) {
          color: #666e7c;
          top: 690px;
          left: 680px;
        }
      }

      .top-logo {
        position: absolute;
        width: 120px;
        top: 40px;
        left: 200px;

        @media (min-width: 1052px) {
          top: 150px;
          left: auto;
          right: 200px;
          width: 240px;
        }

        img {
          width: 100%;
          max-width: 100%;
          height: auto;
        }
      }
      .top-text {
        position: absolute;
        color: #304C1E;
        font-weight: 700;
        text-align: center;
        width: 120px;
        font-size: 14px;
        line-height: 16px;
        top: 140px;
        left: 205px;

        @media (min-width: 1052px) {
          top: 370px;
          left: auto;
          right: 170px;
          width: 260px;
          font-size: 16px;
          line-height: 19px;
          max-width: none;
        }

        .br-1,
        .br-2,
        .br-3,
        .br-4 {
          display: block;

          @media (min-width: 1052px) {
            display: none;
          }
        }
      }
      .animated-button {
        top: 305px;
        right: -190px;
        //top: 396px;
        //right: -130px;
        position: absolute;
        transform: translate(-50%, -50%);

        @media (min-width: 1052px) {
          top: 480px;
          right: 24px;
          //top: 590px;
          //right: 30px;
        }

        $buttonSize: 180px;
        width: $buttonSize * 1.5;
        height: $buttonSize * 1.5;
        font-size: 16px;
        line-height: 20px;

        .circle-1 {
          width: $buttonSize;
          height: $buttonSize;

          .circle-2 {
            $circle2Size: $buttonSize * 0.85;
            height: $circle2Size;
            width: $circle2Size;

            .circle-3 {
              $circle3Size: $buttonSize * 0.75;
              height: $circle3Size;
              width: $circle3Size;
            }
          }
        }
      }

      .videos-text {
        position: absolute;
        top: 10154px;
        left: 10px;
        color: #FFF;
        width: 100%;
        font-size: 14px;
        font-weight: 700;

        @media (min-width: 1052px) {
          font-size: 24px;
          width: auto;
          top: 12620px;
          left: 210px;
        }
      }

      .video-1 {
        position: absolute;
        top: 600px;
        left: 2px;
        height: 203px;

        @media (min-width: 1052px) {
          height: 287px;
          width: 509px;
          top: 780px;
          left: calc(50% + 6px);
        }
      }
      .video-2 {
        position: absolute;
        top: 890px;
        left: 2px;
        height: 203px;

        @media (min-width: 1052px) {
          height: 287px;
          width: 509px;
          top: 1200px;
          left: calc(50% + 6px);
        }
      }
      .video-3 {
        position: absolute;
        top: 1180px;
        left: 2px;
        height: 203px;

        @media (min-width: 1052px) {
          height: 287px;
          width: 509px;
          top: 1620px;
          left: calc(50% + 6px);
        }
      }

      .video-text-1 {
        position: absolute;
        color: #FFF;
        font-weight: 700;
        text-align: center;
        top: 808px;
        left: 2px;
        width: 100%;
        line-height: 20px;
        font-size: 14px;

        @media (min-width: 1052px) {
          font-size: 18px;
          line-height: 26px;
          max-width: 500px;
          top: 1074px;
          left: 280px;
        }
      }

      .video-text-2 {
        position: absolute;
        color: #FFF;
        font-weight: 700;
        text-align: center;
        top: 1100px;
        left: 0;
        width: 100%;
        line-height: 20px;
        font-size: 14px;
        max-width: 360px;

        @media (min-width: 1052px) {
          font-size: 18px;
          line-height: 26px;
          max-width: 500px;
          top: 1496px;
          left: 280px;
        }
      }

      .video-text-3 {
        position: absolute;
        color: #FFF;
        font-weight: 700;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 1394px;
        left: calc(50% - 180px);
        width: 100%;
        font-size: 14px;
        max-width: 400px;

        @media (min-width: 1052px) {
          top: 1916px;
          left: calc(50% - 242px);
          margin-bottom: 10px;
          //font-size: 24px;
          //line-height: 30px;
          font-size: 18px;
          line-height: 26px;
          max-width: 500px;
        }
      }

      .top-video-players {
        height: 203px;
        width: 360px;
        left: 0;
        top: 1180px;

        @media (min-width: 1052px) {
          height: 287px;
          width: 509px;
          left: 534px;
          top: 1620px;
        }
      }
      .top-video-players-btns {
        top: 1436px;
        width: 80%;

        @media (min-width: 1052px) {
          width: 36%;

          top: 1980px;
          left: 332px;
          justify-content: center;
        }
        .core-button {
          margin: 0 10px;
        }
      }

      .trees-circle {
        top: 1756px;
        left: 50%;

        z-index: 2 !important;
        position: absolute;
        transform: translateX(-50%);
        @media (min-width: 1052px) {
          top: 2356px;
        }
        .circle-inner {
          position: relative;

          .circle-text {
            max-width: 200px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            color: #fff;
            display: flex;
            flex-direction: column;

            p {
              font-size: 16px;
              line-height: 19px;
              margin: 20px 0 0;

              padding: 0;
            }
            span {
              white-space: nowrap;
            }

            .more {
              margin-top: 10px;

              font-size: 14px;
              align-self: flex-end;
              display: flex;
              cursor: pointer;

              .text {
                margin-right: 8px;
                text-decoration: underline;

                align-self: center;
                color: #ffffff;
              }

              .svg-pointer {
                width: 14px;
                height: 22px;
              }
            }
          }
        }
      }

      .hollow-nut {
        position: absolute;
        top: 6697px;
        left: 170px;
        @media (min-width: 1052px) {
          top: 8440px;
          left: calc(50% - 5px);
        }
        .nut {
          position: relative;
          img {
            width: 22px;
            height: 20px;
            @media (min-width: 1052px) {
              width: 32px;
              height: 30px;
            }
          }
        }
      }

      .elk-nut-1 {
        opacity: 0;
        bottom: 0;
        position: absolute;

        img {
          width: 20px;
          height: 21px;
          @media (min-width: 1052px) {
            width: 25px;
            height: 26px;
          }
        }
      }

      .sprite-animation {
        position: absolute;
        overflow: hidden;
        img {
          height: 100%;
          max-height: 100%;
          width: auto;
        }
      }
    }
  }
}

a,
a:link,
a:visited,
a:hover,
a:active {
  color: #ffffff;
}
</style>
