<template>
  <svg viewBox="0 0 14.17 21.57" class="svg-pointer">
    <path :fill="color" class="cls-1" d="M1.51,0H5.87A1.7,1.7,0,0,1,7.23.55l6.55,9.1a1.86,1.86,0,0,1,0,2.27L7.22,21a1.69,1.69,0,0,1-1.38.59H1.51c-1.1,0-1.83-.75-1.37-1.4l6.39-9a.69.69,0,0,0,0-.82l-6.39-9C-.32.74.41,0,1.51,0Z" />
  </svg>
</template>

<script>
export default {
  name: 'SvgPointer',
  props: {
    color: {
      type: String,
      default: '#fff',
    },
  },
};
</script>

<style scoped lang="scss">
.svg-pointer {
  width: 14px;
  height: 22px;
}
</style>
