<template>
  <CoreModal
    modal="tasks"
    image-src="/images/modals/tasks.jpg"
  >
    <template v-slot:title>
      У нас лес важных задач!
    </template>
    <template v-slot:text>
      <div>
        Проявляя заботу
        <span class="stop-line-br">о лесе, </span>
        <span class="stop-line-br">мы заботимся </span>
        <span class="stop-line-br">и о людях. </span>
        Ежегодно
        <span class="stop-line-br">на территориях</span> лесных аренд
        <span class="stop-line-br">мы организовываем</span> специальные места
        <span class="stop-line-br">для разведения </span>
        костров, проводим аудиты
        <span class="stop-line-br">и обучение </span>
        <span class="stop-line-br">по безопасности,</span>
        обеспечиваем посетителей
        <span class="stop-line-br">и сотрудников</span> компании
        средствами индивидуальной защиты (специальные
        костюмы, репелленты, обувь
        <span class="stop-line-br">со стальным подноском, </span>
        каски, жилетки
        <span class="stop-line-br">со светоотражающими элементами, </span>
        очки). Ведь безопасность –
        <span class="stop-line-br">это наша</span> ключевая ценность.
        <br><br>
        Кроме того,
        <span class="stop-line-br">мы снабжаем</span> деревни дровами, создаём
        рабочие места для более 2000 местных жителей
        <span class="stop-line-br">и вместе со школьными</span> лесничествами проводим
        образовательные программы.
      </div>
    </template>
  </CoreModal>
</template>

<script>
import CoreModal from '@components/CoreModal';

export default {
  name: 'TasksModal',
  components: { CoreModal },
};
</script>

<style scoped>

</style>
