<template>
  <div class="nuts-1 nuts">
    <div class="nuts-inner">
      <div ref="nut1" class="nut-1">
        <img src="@/assets/images/home/animations/nut1-1.svg" alt="nut-1">
      </div>
      <div class="nut-2">
        <img src="@/assets/images/home/animations/nut1-2.svg" alt="nut-1">
      </div>
    </div>
  </div>

  <div class="nuts-2 nuts">
    <div class="nuts-inner">
      <div class="nut-1">
        <img src="@/assets/images/home/animations/nut1-1.svg" alt="nut-1">
      </div>
      <div ref="nut2" class="nut-2">
        <img src="@/assets/images/home/animations/nut1-2.svg" alt="nut-1">
      </div>
    </div>
  </div>

</template>

<style lang="scss" scoped>
.nuts {
  position: absolute;
  display: flex;

  .nuts-inner {
    position: relative;
    display: flex;
    .nut-1 {
      position: absolute;
      width: 22px;
      height: 22px;
      @media (min-width: 1052px) {
        width: 28px;
        height: 28px;
      }
    }
    .nut-2 {
      position: absolute;
      width: 22px;
      height: 22px;
      @media (min-width: 1052px) {
        width: 28px;
        height: 28px;
      }
    }
  }
}

.nuts-1 {
  top: 7021px;
  left: 35px;
  @media (min-width: 1052px) {
    top: 8850px;
    left: 280px;
  }
  .nut-1 {
  }
  .nut-2 {
    left: 27px;
    bottom: -5px;
    @media (min-width: 1052px) {
      left: 57px;
      bottom: 2px;
    }
  }
}

.nuts-2 {
  top: 8167px;
  left: 20px;
  @media (min-width: 1052px) {
    top: 10490px;
    left: 230px;
  }
  .nut-1 {
    transform: rotate(90deg);
  }
  .nut-2 {
    left: 40px;
    bottom: 0;
    transform: rotate(30deg);
    @media (min-width: 1052px) {
      left: 60px;
    }
  }
}
</style>
