<template>
  <teleport to="body" @scroll.stop>
    <div v-if="isTestOpened" class="test">
      <TestClose @click="closeTest" />
      <TestStart
        v-if="state === 'start'"
        @start="setState('questions')"
      />
      <template v-if="state === 'questions'">
        <TestQuestion
          :key="questionsNumber.current"
          :question="questionsList[questionsNumber.current]"
          :questionsNumber="questionsNumber"
          @answer="handleAnswer"
          @next="nextQuestion"
        />
      </template>
      <template v-if="state === 'end'">
        <TestEnd
          :success="questionsNumber.success"
          :total="questionsNumber.total"
          @repeat="handleTestRepeat"
          @close="closeTest"
        />
      </template>
    </div>
  </teleport>
</template>

<script>
import TestEnd from '@components/test/TestEnd.vue';
import { mapState, mapMutations } from 'vuex';
import questionsList from './questionsList';

import TestStart from './TestStart.vue';
import TestClose from './TestClose.vue';
import TestQuestion from './TestQuestion.vue';

export default {
  name: 'Test',
  components: {
    TestEnd,
    TestQuestion,
    TestClose,
    TestStart,
  },
  data: () => ({
    questionsNumber: {
      total: 12,
      current: 0,
      success: 0,
    },
    questionsList,
    state: 'start',
  }),
  computed: {
    ...mapState(['modals']),
    isTestOpened() {
      return this.modals.test;
    },
  },
  methods: {
    ...mapMutations(['$m_closeModal']),
    closeTest() {
      this.handleTestRepeat();
      this.$m_closeModal('test');
    },
    setState(newState) {
      this.state = newState;
    },
    nextQuestion() {
      if (this.questionsNumber.current < this.questionsNumber.total - 1) {
        this.questionsNumber.current++;
      } else {
        this.setState('end');
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'GA_Event',
          eventCategory: 'page',
          eventAction: 'testFinish',
        });
      }

      if (this.questionsNumber.current === 6) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'GA_Event',
          eventCategory: 'button',
          eventAction: 'clickSeven',
        });
      }
    },
    handleAnswer(correct) {
      if (correct) this.questionsNumber.success++;
    },
    handleTestRepeat() {
      this.questionsNumber = {
        total: 12,
        current: 0,
        success: 0,
      };
      this.state = 'start';
    },
  },
};
</script>

<style scoped lang="scss">
  .test {
    position: fixed;
    z-index: 99999;
    background: #fff;
    min-height: calc(100vh + 10px);
    height: 100%;
    //min-height: 100vh;
    //height: fit-content;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    //@media (min-width: 2000px) {
    //  display: flex;
    //  justify-content: center;
    //  align-items: center;
    //}
  }
</style>
