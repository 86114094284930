<template>
  <div v-bind="$attrs" class="video-container-1">
    <video
      ref="video"
      playsinline
      controls
    />
  </div>
<!--  <div v-bind="$attrs" :class="{'hide-video': !plyrDisable}" class="video-container-1 mobile">-->
<!--    <video-->
<!--      ref="videoMobile"-->
<!--      playsinline-->
<!--      controls-->
<!--    />-->
<!--  </div>-->
</template>

<script>
import Plyr from 'plyr';

export default {
  name: 'VideoPlayer',
  props: {
    videoSrc: {
      type: String,
      required: true,
    },
    poster: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    plyrDisable: false,
    windowScale: 1,
    playerOptions: {
      fullscreen: {
        iosNative: true,
      },
      controls: [
        'play-large',
        'play',
        'progress',
        'current-time',
        'mute',
        'volume',
        'airplay',
        'fullscreen',
      ],
      ratio: '16:9',
    },
    source: {
      type: 'video',
      title: 'Example title',
      sources: [
        {
          src: '',
          provider: 'youtube',
          size: 720,
        },
      ],
    },
    player1: null,
  }),
  mounted() {
    this.source.sources[0].src = this.videoSrc;

    this.onResize();
    window.addEventListener('resize', this.onResize);
    this.initPlyr();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    initPlyr() {
      this.player1 = new Plyr(this.$refs.video, this.playerOptions);
      // this.player1.source = this.sources[0];
      this.openVideo();
    },
    openVideo() {
      this.player1.source = this.source;
      // this.$refs.videoMobile.src = this.source.sources[0].src;
      this.player1.poster = this.poster;
      // this.$refs.videoMobile.poster = this.poster;
    },
    onResize() {
      const metaScale = document.head.querySelector('meta[name="viewport"]');

      if (metaScale.content !== 'width=device-width,initial-scale=1.0') this.windowScale = 0.8;
      else this.windowScale = 1;

      if ((window.innerWidth * this.windowScale) < 1052) {
        this.plyrDisable = true;
        return;
      }
      this.plyrDisable = false;
    },
  },
};
</script>

<style lang="scss">
.video-container-1 {
  top: 10184px;
  left: 1px;

  z-index: 9999 !important;
  height: 208px;
  width: 100%;
  position: absolute;
  background-color: #000;
  display: flex;

  @media (min-width: 1052px) {
    top: 12668px;
    //height: 425px;
    height: 355px;
    width: 630px;
    left: calc(50% - 18px);
    transform: translateX(-50%);
  }

  > * {
    z-index: 100;
    width: 100%;
    height: 100%;
  }
}

.video-container-2 {
  top: 10590px;
  left: 0;

  width: 100%;
  height: 203px;
  z-index: 9999 !important;
  background-color: #000;
  position: absolute;

  @media (min-width: 1052px) {
    top: 12830px;
    left: 50%;
    transform: translateX(-50%);
    width: 690px;
    height: 390px;
  }

  > * {
    z-index: 100;
    position: relative;
    width: 100%;
    height: 100%;
    >* {
      z-index: 100;
    }
  }
}

.hide-video {
  z-index: 0 !important;
}

:root {
  --plyr-color-main: #B0BE23 !important;
}
</style>
